import { useMemo } from "react";
import Masonry from "react-responsive-masonry";
import { useOnMount } from "../../../../hooks/useOnMount.ts";
import { MasonryAssetTile } from "../../components/AssetImageTile/MasonryAssetTile.tsx";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../hooks/useSelectedAssetUuid.ts";
import { tagsFilterStore } from "../../stores/tagsFilterStore.ts";
import { EmptyStateInformation } from "../components/EmptyStateInformation.tsx";
import { TagsFilterBar } from "./TagsFilterBar.tsx";
import { filterAssetsByTags } from "./utils/tagFiltering.ts";

export const AssetsWorkspace = () => {
  const { board } = useBoard();
  const { selectedTagsFilter } = tagsFilterStore.useState();

  const { selectedAssetUuid, clearSelectedAssetUuid } = useSelectedAssetUuid();

  useOnMount(() => {
    if (selectedAssetUuid) {
      document
        .getElementById(`AssetImageTile-${selectedAssetUuid}`)
        ?.scrollIntoView({ inline: "center", behavior: "instant" });
      clearSelectedAssetUuid();
    }
  });

  const filteredAssets = useMemo(
    () =>
      filterAssetsByTags({
        assets: board.assets,
        filterTags: selectedTagsFilter,
      }),
    [board.assets, selectedTagsFilter],
  );

  return (
    <div className="pt-2xl relative flex-fill flex-col w-full gap-sm">
      <div className="flex-row justify-between pr-xl">
        <TagsFilterBar tagsFilter={board.tags} />
      </div>
      {filteredAssets.length ? (
        <div className="px-xl pt-md pb-2xl flex-fill flex-col w-full overflow-auto">
          <Masonry columnsCount={4} gutter="6px">
            {filteredAssets.map((asset) => (
              <MasonryAssetTile
                key={asset.uuid}
                asset={asset}
                favoriteTagUuid={
                  board.tags.find((it) => it.name === FAVORITE_TAG_NAME)?.uuid
                }
              />
            ))}
          </Masonry>
        </div>
      ) : selectedTagsFilter.length === 1 ? (
        selectedTagsFilter[0].name === FAVORITE_TAG_NAME ? (
          <EmptyStateInformation
            title="no favorites yet!"
            body="Click on the heart on any image to save them here."
          />
        ) : (
          <EmptyStateInformation
            title="nothing here yet!"
            body="Tag images to see them here."
          />
        )
      ) : (
        <EmptyStateInformation
          title="nothing here yet!"
          body="Go to Create to start generating."
        />
      )}
    </div>
  );
};
