import type { StyleColorKind, StyleImagesKind } from "../../types.ts";

export const StyleTypeDisplay: Record<StyleImagesKind, string> = {
  photography: "Photography",
  illustration_2d: "2D Illustration",
  graphic_3d: "3D Graphic",
  icon: "Icon",
  other: "Other",
} as const;

export const ColorTypeDisplay: Record<StyleColorKind, string> = {
  black_and_white: "Black and White",
  monochrome: "Monochrome",
  bland: "Bland",
  regular: "Regular",
  bright: "Bright",
  other: "Other",
} as const;
