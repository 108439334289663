import { produce } from "immer";
import {
  type CSSProperties,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { ZodSchema } from "zod";
import { useIsMounted } from "../../hooks/useIsMounted.ts";
import { useStableCallback } from "../../hooks/useSyncRef.ts";
import { createStore } from "../../utils/createStore.ts";
import {
  type FormActions,
  FormContext,
  type FormError,
  type FormState,
  type FormStore,
} from "./FormContext.ts";

export const Form = <Schema extends ZodSchema>({
  initialValues,
  schema,
  children,
  className,
  style,
  disabled = false,
  onSubmit,
}: {
  initialValues: Schema["_input"];
  schema: Schema;
  children: ReactNode | ((store: FormStore<Schema["_input"]>) => ReactNode);
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  onSubmit: (
    values: Schema["_output"],
    context: { initialValues: Schema["_input"] },
  ) => Promise<unknown>;
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const isMounted = useIsMounted();
  const stableOnSubmit = useStableCallback(onSubmit);

  const getErrors = (values: Schema["_input"]): FormError[] => {
    const result = schema.safeParse(values as string[]);
    return result.success
      ? []
      : result.error.errors.map((e) => ({
          message: e.message,
          path: e.path.join("."),
        }));
  };

  const getInitialState = (): FormState<Schema["_input"]> => ({
    disabled,

    isSubmitting: false,
    submitted: false,
    dirty: false,
    values: initialValues,
    initialValues,
    errors: getErrors(initialValues),
  });

  const [store] = useState(() =>
    createStore(
      getInitialState(),
      ({
        get,
        set,
      }): FormActions<Schema["_input"]> & {
        setState: (value: Partial<FormState<Schema["_input"]>>) => void;
      } => ({
        setState: set,
        getError: (path, opts) => {
          if (!get().submitted && !opts?.force) return;
          const stringPath = callbackToStringPath(path);
          return get().errors.find((e) =>
            opts?.partial
              ? e.path.startsWith(stringPath)
              : e.path === stringPath,
          )?.message;
        },
        useError: (path, opts) =>
          store.useState(() => store.getError(path, opts)),
        useValue: (selector, shallow) =>
          store.useState(
            (s) => (selector ? selector(s.values) : s.values),
            shallow,
          ),
        setValues: (payloadOrFn) => {
          const newValues =
            typeof payloadOrFn === "function"
              ? produce<Schema["_input"]>(get().values, payloadOrFn)
              : { ...get().values, ...payloadOrFn };
          set({
            values: newValues,
            dirty: true,
            errors: getErrors(newValues),
          });
        },
        reset: () => set(getInitialState()),
        submit: () => {
          set({ submitted: true });
          const result = schema.safeParse(get().values);
          if (!result.success) {
            setTimeout(() => {
              ref.current
                ?.querySelector('[data-error="true"]')
                ?.scrollIntoView({ block: "center", behavior: "smooth" });
            }, 50);
            return;
          }
          set({ isSubmitting: true });
          stableOnSubmit(result.data, {
            initialValues: get().initialValues,
          })
            .then(() => {
              if (isMounted.current) {
                set({
                  isSubmitting: false,
                  submitted: false,
                  dirty: false,
                });
              }
            })
            .catch(() => {
              if (isMounted.current) set({ isSubmitting: false });
            });
        },
      }),
    ),
  );

  useEffect(() => {
    store.setState({ disabled });
  }, [store, disabled]);

  return (
    <FormContext.Provider value={store}>
      <form
        ref={ref}
        noValidate
        className={className}
        style={style}
        onSubmit={(e) => {
          e.preventDefault();
          store.submit(true);
        }}
      >
        {typeof children === "function" ? children(store) : children}
      </form>
    </FormContext.Provider>
  );
};

const emptyTarget = {};
const callbackToStringPath = (cb: (proxy: unknown) => unknown) => {
  const path: string[] = [];
  const handler: ProxyHandler<object> = {
    get(_, prop) {
      path.push(prop as string);
      return new Proxy(emptyTarget, handler);
    },
  };
  cb(new Proxy(emptyTarget, handler));
  return path.join(".");
};
