import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { CREATE_WORKSPACE, EDIT_WORKSPACE } from "../../../routes.ts";
import { CreateToolMenu } from "./CreateToolMenu.tsx";
import { EditToolMenu } from "./EditToolMenu.tsx";

export const ToolMenu = () => (
  <Routes>
    <Route path={`${CREATE_WORKSPACE}/*`} element={<CreateToolMenu />} />
    <Route path={`${EDIT_WORKSPACE}/*`} element={<EditToolMenu />} />
  </Routes>
);
