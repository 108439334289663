import classNames from "classnames";
import { fabric } from "fabric";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BaseButton } from "../../../../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../../../../components/Icon/Icon.tsx";
import { Kbd } from "../../../../../../components/Kbd/Kbd.tsx";
import { Popover } from "../../../../../../components/Popover/Popover.tsx";
import { Slider } from "../../../../../../components/Slider/Slider.tsx";
import { useIsMacintosh } from "../../../../../../hooks/useIsMacintosh.ts";
import { ColorPicker } from "../../../../components/ColorPicker.tsx";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import {
  clearObjects,
  removeImageBackground,
  updateActiveObjectsColor,
} from "../../components/BaseEditor/utils.ts";
import {
  addImage,
  setDrawingCursor,
} from "../../components/ImageEditor/utils.tsx";
import { drawCanvasStore } from "./stores/drawCanvasStore.ts";
import { ToolbarIconButton } from "./ToolbarIconButton.tsx";

export const DrawToolbar = () => {
  const { fabricCanvas, numObjectsCreated, numHistoryActions } =
    drawCanvasStore.useState();

  const { selectedAsset } = useSelectedAsset();

  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  useEffect(() => {
    // @ts-expect-error history.js is not typed
    setCanUndo(fabricCanvas?.canUndo());
    // @ts-expect-error history.js is not typed
    setCanRedo(fabricCanvas?.canRedo());
  }, [fabricCanvas, numHistoryActions, selectedAsset]);

  //  XXX: Instead of using directly the numObjectsCreated, we rather check directly if the canvas is empty to avoid
  //  any potential side effects when mixing history modifications and object creations.
  useEffect(() => {
    setIsEmpty(fabricCanvas?.isEmpty() ?? false);
  }, [fabricCanvas, numObjectsCreated, selectedAsset]);

  const [isDrawingMode, setIsDrawingMode] = useState(true);
  const [brushSize, setBrushSize] = useState(20);
  const [selectedColor, setSelectedColor] = useState("#F3F20E");

  const [selectedCanvasImage, setSelectedCanvasImage] =
    useState<fabric.Image>();
  const [isRemovingBackground, setIsRemovingBackground] = useState(false);

  const isMacintosh = useIsMacintosh();

  useEffect(() => {
    if (fabricCanvas === undefined) return;
    fabricCanvas.freeDrawingBrush.width = brushSize;
    fabricCanvas.freeDrawingBrush.color = selectedColor;
    setDrawingCursor({
      fabricCanvas,
      brushSize,
      brushColor: selectedColor,
    });
    fabricCanvas.on("selection:created", (event) => {
      if (event.selected?.length === 1 && event.selected[0].type === "image") {
        setSelectedCanvasImage(event.selected[0] as fabric.Image);
      }
    });
    fabricCanvas.on("selection:updated", (event) => {
      if (event.selected?.length === 1 && event.selected[0].type === "image") {
        setSelectedCanvasImage(event.selected[0] as fabric.Image);
      } else {
        setSelectedCanvasImage(undefined);
      }
    });
    fabricCanvas.on("selection:cleared", () =>
      setSelectedCanvasImage(undefined),
    );
  }, [fabricCanvas, brushSize, selectedColor]);

  const [isImageMenuOpen, setIsImageMenuOpen] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const {
    getRootProps,
    getInputProps,
    open: openFileBrowser,
  } = useDropzone({
    multiple: false,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: (acceptedFile) => {
      if (fabricCanvas === undefined) return;
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile[0]);
      reader.onload = function () {
        if (typeof reader.result === "string") {
          addImage({
            fabricCanvas,
            url: reader.result,
            callback: () => {
              setMode("selection");
              selectLastAddedObject();
            },
          });
          setIsImageMenuOpen(false);
        }
      };
    },
  });
  const selectLastAddedObject = () => {
    const lastAddedObject = fabricCanvas?.getObjects().at(-1);
    if (lastAddedObject !== undefined) {
      fabricCanvas?.setActiveObject(lastAddedObject);
    }
    setMode("selection");
  };

  const setMode = (mode: "selection" | "drawing") => {
    if (fabricCanvas === undefined) return;
    if (mode === "selection") {
      fabricCanvas.isDrawingMode = false;
      setIsDrawingMode(false);
    } else {
      fabricCanvas.isDrawingMode = true;
      setIsDrawingMode(true);
    }
  };

  const addSquare = () => {
    if (fabricCanvas === undefined) return;
    fabricCanvas.add(
      new fabric.Rect({
        height: 100,
        width: 100,
        originX: "center",
        originY: "center",
        fill: selectedColor,
        top: (fabricCanvas.height ?? 0) / 2,
        left: (fabricCanvas.width ?? 0) / 2,
      }),
    );

    selectLastAddedObject();
  };

  // FIXME: Update the layout based on the design
  return fabricCanvas !== undefined && selectedAsset ? (
    <div className="flex-row h-full gap-lg flex-fill place-content-center">
      <ToolbarIconButton
        iconName="MousePointer2"
        onClick={() => {
          setMode("selection");
        }}
        isSelected={!isDrawingMode}
        tooltip={{ content: "Selection mode" }}
      />
      <ToolbarIconButton
        iconName="Brush"
        isSelected={isDrawingMode}
        onClick={() => {
          fabricCanvas.freeDrawingBrush.color = selectedColor;
          setMode("drawing");
          setDrawingCursor({
            fabricCanvas,
            brushSize,
            brushColor: selectedColor,
          });
        }}
        optionContent={
          <div className="p-md flex-col gap-sm">
            <div>Size</div>
            <Slider
              min={1}
              max={130}
              value={brushSize}
              onChange={(value) => {
                fabricCanvas.freeDrawingBrush.width = value;
                setDrawingCursor({
                  fabricCanvas,
                  brushSize: value,
                  brushColor: selectedColor,
                });
                setBrushSize(value);
              }}
              className="w-[120px] h-[35px]"
            />
          </div>
        }
        tooltip={{ content: "Draw mode" }}
      />
      <ToolbarIconButton
        iconName="Square"
        onClick={addSquare}
        optionContent={
          <div className="flex-row-center gap-xl px-xl h-[40px]">
            <ToolbarIconButton
              iconName="Circle"
              onClick={() => {
                fabricCanvas.add(
                  new fabric.Circle({
                    radius: 50,
                    originX: "center",
                    originY: "center",
                    fill: selectedColor,
                    top: (fabricCanvas.height ?? 0) / 2,
                    left: (fabricCanvas.width ?? 0) / 2,
                  }),
                );
                selectLastAddedObject();
              }}
            />
            <ToolbarIconButton
              iconName="Triangle"
              onClick={() => {
                fabricCanvas.add(
                  new fabric.Triangle({
                    height: 100,
                    width: 100,
                    originX: "center",
                    originY: "center",
                    fill: selectedColor,
                    top: (fabricCanvas.height ?? 0) / 2,
                    left: (fabricCanvas.width ?? 0) / 2,
                  }),
                );
                selectLastAddedObject();
              }}
            />
            <ToolbarIconButton iconName="Square" onClick={addSquare} />
          </div>
        }
        tooltip={{ content: "Shapes" }}
      />
      <Popover
        modal
        isOpen={isColorPickerOpen}
        onOpenChange={(open) => {
          setIsColorPickerOpen(open);
        }}
        content={
          <div className="p-sm h-[200px] w-[200px] flex-col">
            <ColorPicker
              className="flex-fill"
              color={selectedColor}
              onColorChange={(hexColor) => {
                fabricCanvas.freeDrawingBrush.color = hexColor;
                setDrawingCursor({
                  fabricCanvas,
                  brushSize,
                  brushColor: hexColor,
                });
                updateActiveObjectsColor({ fabricCanvas, color: hexColor });
                setSelectedColor(hexColor);
              }}
            />
          </div>
        }
        side="bottom"
        className="mt-4"
      >
        <BaseButton
          className="group h-full flex-row-center hover:opacity-70"
          onClick={() => setIsColorPickerOpen(true)}
        >
          <div
            className={classNames(
              isColorPickerOpen
                ? "border-[1.5px] border-active-button"
                : "border",
              "h-20 w-20 rounded-full group-hover:border-pimento-blue",
            )}
            style={{ backgroundColor: selectedColor }}
          />
        </BaseButton>
      </Popover>
      <HeaderSeparator />
      <Popover
        isOpen={isImageMenuOpen}
        onOpenChange={(open) => {
          setIsImageMenuOpen(open);
        }}
        modal
        content={
          <div className="p-sm w-[200px] flex-col">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button
                type="button"
                className="p-2xl flex-col-center w-full gap-2xl rounded-sm border border-dashed border-neutral-200 bg-stone-50 text-center text-sm text-gray-400"
                onClick={() => openFileBrowser()}
              >
                <Icon
                  name="ImagePlus"
                  size={30}
                  className="stroke-gray-400 stroke-[1px]"
                />
                <span>
                  Drag image or&nbsp;
                  <span className="text-pimento-blue underline font-semibold">
                    open folder
                  </span>
                </span>
              </button>
            </div>
          </div>
        }
        side="bottom"
        className="mt-4"
      >
        <ToolbarIconButton
          iconName="ImagePlus"
          tooltip={{ content: "Add image" }}
          isSelected={isImageMenuOpen}
        />
      </Popover>
      <ToolbarIconButton
        iconName="RemoveBackground"
        isDisabled={selectedCanvasImage === undefined}
        onClick={() => {
          setIsRemovingBackground(true);
          if (selectedCanvasImage !== undefined) {
            void removeImageBackground({
              image: selectedCanvasImage,
              callback: () => setIsRemovingBackground(false),
            });
          }
        }}
        tooltip={{
          content:
            selectedCanvasImage === undefined
              ? "Select first an image to remove its background"
              : "Remove background",
        }}
        isLoading={isRemovingBackground}
      />
      <HeaderSeparator />
      <BaseButton
        className="h-full flex-row-center hover:text-pimento-blue disabled:text-gray-350"
        onClick={() => {
          clearObjects(fabricCanvas);
          // @ts-expect-error history.js is not typed
          fabricCanvas.clearHistory();
        }}
        disabled={isEmpty}
      >
        Clear
      </BaseButton>
      <ToolbarIconButton
        iconName="Undo"
        onClick={() => {
          // @ts-expect-error history.js is not typed
          fabricCanvas.undo();
        }}
        isDisabled={!canUndo}
        tooltip={{
          content: (
            <div className="flex-row items-center gap-md">
              Undo
              <div className="flex-row-center gap-sm">
                <Kbd>
                  {isMacintosh ? <Icon name="Command" size={12} /> : "Ctrl"}
                </Kbd>
                <Kbd>Z</Kbd>
              </div>
            </div>
          ),
        }}
      />
      <ToolbarIconButton
        iconName="Redo"
        onClick={() => {
          // @ts-expect-error history.js is not typed
          fabricCanvas.redo();
        }}
        isDisabled={!canRedo}
        tooltip={{
          content: (
            <div className="flex-row items-center gap-md">
              Redo
              <div className="flex-row-center gap-sm">
                <Kbd>
                  {isMacintosh ? <Icon name="Command" size={12} /> : "Ctrl"}
                </Kbd>
                <Kbd>
                  <Icon name="ArrowBigUp" size={14} />
                </Kbd>
                <Kbd>Z</Kbd>
              </div>
            </div>
          ),
        }}
      />
    </div>
  ) : null;
};

const HeaderSeparator = () => <div className="border-l h-full" />;
