import { createStore } from "../../../utils/createStore.ts";

export const informationMenuDisplayStore = createStore(
  {
    isInformationMenuOpen: true,
  },
  ({ get, set }) => ({
    toggleInformationMenuDisplayState: () =>
      set({
        isInformationMenuOpen: !get().isInformationMenuOpen,
      }),
  }),
);
