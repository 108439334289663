import { useCallback, useRef } from "react";

export const useSyncRef = <T>(value: T) => {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
};

export const useStableCallback = <T extends (...args: any[]) => any>(cb: T) => {
  const ref = useSyncRef<T>(cb);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => ref.current(...args)) as T, []);
};
