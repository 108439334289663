import { createStore } from "../../../utils/createStore.ts";

type SelectedFilter = { uuid: string; name: string };

export type SelectedFilters = SelectedFilter[];

// FIXME: Remove the automatic tagging with active tags on generation
export const tagsFilterStore = createStore(
  {
    selectedTagsFilter: [] as SelectedFilters,
  },
  ({ get, set }) => ({
    addTagToFilter: ({ uuid, name }: SelectedFilter) =>
      set({
        selectedTagsFilter: [
          ...get().selectedTagsFilter.filter((it) => it.uuid !== uuid),
          { uuid, name },
        ],
      }),
    removeTagFromFilter: ({ uuid }: { uuid: string }) =>
      set({
        selectedTagsFilter: get().selectedTagsFilter.filter(
          (tag) => tag.uuid !== uuid,
        ),
      }),
    resetTagsFilter: () =>
      set({
        selectedTagsFilter: [],
      }),
  }),
);
