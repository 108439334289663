import { useParams } from "react-router";
import { Query } from "../../components/Query/Query.tsx";
import { BoardContent } from "./BoardContent.tsx";
import { showDeletedAssetsStore } from "./stores/showDeletedAssetsStore.ts";

export const BoardView = () => {
  const boardUuid = useParams()["boardUuid"]!;

  const { showDeletedAssets } = showDeletedAssetsStore.useState();

  return (
    <Query
      queryKey={`boards/${boardUuid}`}
      queryParamsKey={
        showDeletedAssets
          ? { include_deleted: showDeletedAssets.toString() }
          : undefined
      }
    >
      {() => <BoardContent />}
    </Query>
  );
};
