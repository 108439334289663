import classNames from "classnames";
import { Image } from "../../../components/Image/Image.tsx";
import type { ImageContent } from "../types.ts";
import { LoadingTile } from "./LoadingTile.tsx";

export const ImageTile = ({
  image,
  loadingContentClassName,
  loading = "eager",
  fit,
}: {
  image: ImageContent;
  fit: "width" | "height";
  loadingContentClassName?: string;
  loading?: "eager" | "lazy";
}) => {
  if (image.status === "processing") {
    return (
      <LoadingTile
        image={image}
        className={classNames(
          fit === "width" ? "w-full" : "h-full",
          "aspect-square",
          loadingContentClassName,
        )}
      />
    );
  }

  return (
    <div className={fit === "width" ? "w-full" : "h-full"}>
      <Image
        className={fit === "width" ? "w-full" : "h-full"}
        imageClassName={fit === "width" ? "w-full" : "h-full"}
        src={image.url}
        loading={loading}
      />
    </div>
  );
};
