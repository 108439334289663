import classNames from "classnames";
import { useEffect } from "react";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { ImageDropZone } from "../../../components/ImageDropZone/ImageDropZone.tsx";
import { ImageTile } from "../../../components/ImageTile.tsx";
import { useAddImageOnBoard } from "../../../hooks/useAddImageOnBoard.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../../hooks/useSelectedAssetUuid.ts";

export const ImageStripe = () => {
  const { board } = useBoard();
  const { selectedAssetUuid, setSelectedAssetUuid } = useSelectedAssetUuid();

  useEffect(() => {
    document
      .getElementById(selectedAssetUuid ?? "")
      ?.scrollIntoView({ inline: "center", behavior: "instant" });
  }, [selectedAssetUuid]);

  return (
    <div className="h-full px-lg py-sm flex-row items-end gap-lg">
      <div
        className={classNames(
          "aspect-square",
          selectedAssetUuid ? "h-[90%]" : "h-full",
        )}
      >
        <AddImageButton />
      </div>
      <div
        id="image-stripe"
        className="h-full flex-row flex-fill items-end gap-md overflow-x-auto"
      >
        {board.assets.map((asset) => (
          <div
            key={asset.uuid}
            id={asset.uuid}
            className={classNames(
              asset.uuid === selectedAssetUuid
                ? "h-full border-pimento-blue border-[2px]"
                : "h-[90%]",
              "relative cursor-pointer",
            )}
            onClick={() => {
              setSelectedAssetUuid(asset.uuid);
            }}
          >
            {asset.deleted_at !== null && (
              <Icon
                name="Trash"
                className="z-10 absolute bottom-0 right-0 p-xs bg-pimento-red stroke-white"
              />
            )}
            <ImageTile
              image={asset.content}
              loading="lazy"
              fit="height"
              loadingContentClassName="text-xs !p-0 !bg-gray-100"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const AddImageButton = () => {
  const { setSelectedAssetUuid } = useSelectedAssetUuid();
  const { mutate: uploadImage, isLoading: isUploadLoading } =
    useAddImageOnBoard({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onUploaded: (asset_uuid) => {
        setSelectedAssetUuid(asset_uuid);
      },
    });
  return (
    <ImageDropZone
      onDrop={(file) => {
        if (file) {
          uploadImage({
            image: file.fileToUpload,
          });
        }
      }}
      content={
        isUploadLoading ? (
          <Spinner />
        ) : (
          <Icon name="ImagePlus" size={22} className="stroke-pimento-blue" />
        )
      }
    />
  );
};
