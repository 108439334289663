export const OnboardingCharacterAngle2 = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.75" y="0.75" width="69.5" height="69.5" rx="4.25" />
    <path
      d="M61.1109 71.5035V64.6937C61.1109 61.0816 59.6593 57.6174 57.0755 55.0633C54.4916 52.5091 50.9872 51.0742 47.3331 51.0742H26.6665C23.0124 51.0742 19.5079 52.5091 16.9241 55.0633C14.3403 57.6174 12.8887 61.0816 12.8887 64.6937V71.5035"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7778 45.239C44.387 45.239 50.5556 39.1413 50.5556 31.6195C50.5556 24.0977 44.387 18 36.7778 18C29.1685 18 23 24.0977 23 31.6195C23 39.1413 29.1685 45.239 36.7778 45.239Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
