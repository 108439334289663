import classNames from "classnames";
import { Animation } from "../../components/Animation/Animation.tsx";
import { Button } from "../../components/Button/Button.tsx";
import { Dialog } from "../../components/Dialog/Dialog.tsx";
import { Icon } from "../../components/Icon/Icon.tsx";
import { useCreateCheckoutUrl } from "../../views/Homepage/hooks/useCreateCheckoutUrl.ts";
import { upgradeDialogStore } from "./upgradeDialogStore.ts";

export const UpgradeDialog = () => {
  const { isDialogOpen } = upgradeDialogStore.useState();

  return (
    <Dialog
      isOpen={isDialogOpen}
      onOpenChange={upgradeDialogStore.setDialogOpen}
      clickOutsideAuthorized
      content={isDialogOpen && <DialogContent />}
    />
  );
};

const DialogContent = () => {
  const { createCheckoutUrl, isCreateCheckoutUrlLoading } =
    useCreateCheckoutUrl();
  const singlePaneDimensions = "h-[550px] w-[400px]";

  return (
    <div className="flex-row">
      <div
        className={classNames("bg-black content-center", singlePaneDimensions)}
      >
        <Animation
          loadData={() => import("../../assets/boardInitLoading.json")}
          loop
          autoplay
        />
      </div>
      <div
        className={classNames(
          "flex-col px-2xl gap-3xl justify-center bg-white",
          singlePaneDimensions,
        )}
      >
        <div className="flex-col text-gray-600">
          <span className="font-burg text-4xl">upgrade to pro</span>
          <span className="text-xl font-semibold">$39/month</span>
        </div>
        <div className="flex-col gap-md">
          {[
            "200 daily credits",
            "Unlimited custom models",
            "Access to all library models",
            "Dedicated customer support",
          ].map((item) => (
            <div key={item} className="flex-row gap-sm items-center">
              <Icon name="Check" size={12} className="stroke-gray-600" />
              <span className="text-lg text-gray-600">{item}</span>
            </div>
          ))}
        </div>
        <Button
          className="w-full"
          loading={isCreateCheckoutUrlLoading}
          onClick={() => {
            createCheckoutUrl({});
          }}
        >
          Start 7-day free trial
        </Button>
      </div>
    </div>
  );
};
