import { useQueryClient } from "@tanstack/react-query";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useVariationSettings } from "./useVariationSettings.ts";

export const useVariationGeneration = () => {
  const { board } = useBoard();
  const { variationSettings } = useVariationSettings();

  const generateImageVariationMutation = useAppMutation({
    path: "boards/generate-image-variation",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
  }).mutation;

  const queryClient = useQueryClient();
  return {
    mutation: ({
      prompt,
      styleUuid,
      assetUuid,
    }: {
      prompt: string;
      styleUuid: string;
      assetUuid: string;
    }) => {
      generateImageVariationMutation.mutate(
        {
          board_uuid: board.uuid,
          asset_uuid: assetUuid,
          prompt,
          style_uuid: styleUuid,
          num_generations: variationSettings.num_generations,
          prompt_strength:
            variationSettings.creativity_strength_preset === "custom"
              ? variationSettings.prompt_strength
              : undefined,
          lora_scale: variationSettings.lora_scale,
          negative_prompt: variationSettings.negative_prompt,
          prompt_enhancement: variationSettings.prompt_enhancement,
          quality_preset: variationSettings.quality_preset,
          creativity_strength_preset:
            variationSettings.creativity_strength_preset,
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries({
              queryKey: [`assets/${assetUuid}`],
            });
          },
        },
      );
    },
    isLoading: generateImageVariationMutation.isLoading,
  };
};
