import classNames from "classnames";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Icon } from "../../../components/Icon/Icon.tsx";

export const ColorPicker = ({
  className,
  color,
  onColorChange,
}: {
  className?: string;
  color: string;
  onColorChange: (color: string) => void;
}) => {
  const openEyeDropper = async () => {
    // XXX: the native EyeDropper is not available in Safari
    // https://developer.mozilla.org/en-US/docs/Web/API/EyeDropper_API
    if ("EyeDropper" in window) {
      const eyeDropper = new (window.EyeDropper as any)();
      try {
        const result = await eyeDropper.open();
        onColorChange(result.sRGBHex);
      } catch (error) {
        console.error("EyeDropper API failed", error);
      }
    } else {
      console.log("EyeDropper API not available in this browser.");
    }
  };

  return (
    <div className={classNames("flex-col", className)}>
      <HexColorPicker
        className="flex-fill"
        color={color}
        onChange={onColorChange}
      />
      <div className="relative flex-row items-center">
        <div className="text-sm">HEX #</div>
        <HexColorInput
          autoFocus
          data-form-type="other"
          className="text-sm"
          color={color}
          onChange={onColorChange}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {"EyeDropper" in window && (
          <button
            type="button"
            onClick={openEyeDropper}
            className="absolute right-0 p-sm hover:opacity-70"
          >
            <Icon name="Pipette" />
          </button>
        )}
      </div>
    </div>
  );
};
