import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";

export const useGetSelectedAssetInitImageUuid = () => {
  const { selectedAsset } = useSelectedAsset();

  return selectedAsset &&
    selectedAsset.content.status === "succeeded" &&
    (selectedAsset.content.generation_model_type === "upscaler" ||
      selectedAsset.content.generation_model_type === "image_to_image" ||
      selectedAsset.content.generation_model_type === "style_transfer" ||
      selectedAsset.content.generation_model_type === "generative_fill")
    ? selectedAsset.content.generation_model_params.init_image_uuid
    : undefined;
};
