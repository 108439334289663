export const OnboardingObjectAngle2 = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.75" y="0.75" width="69.5" height="69.5" rx="4.25" />
    <path
      d="M51.75 28.0009L48.25 31.5009L45.625 25.0259C45.3774 24.3633 44.9349 23.7912 44.3557 23.385C43.7765 22.9789 43.0879 22.7578 42.3805 22.7509H29.7C28.9869 22.7345 28.2858 22.9364 27.6906 23.3296C27.0954 23.7227 26.6346 24.2883 26.3698 24.9507L23.75 31.5009L20.25 28.0009"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.25 38.5H27.2675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.75 38.5H44.7675"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.25 31.5H23.75C21.817 31.5 20.25 33.067 20.25 35V42C20.25 43.933 21.817 45.5 23.75 45.5H48.25C50.183 45.5 51.75 43.933 51.75 42V35C51.75 33.067 50.183 31.5 48.25 31.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M23.75 45.5V49" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M48.25 45.5V49" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
