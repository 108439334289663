import { useState } from "react";
import { SizeObservableWrapper } from "../../../../../../components/SizeObservableWrapper/SizeObservableWrapper.tsx";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import { BaseImageContainer } from "../../components/ImageContainer/BaseImageContainer.tsx";
import { ImageEditor } from "../../components/ImageEditor/ImageEditor.tsx";
import { drawCanvasStore } from "./stores/drawCanvasStore.ts";

export const MagicDrawTool = () => {
  const [maxSize, setMaxSize] = useState<{ width: number; height: number }>();
  const { fabricCanvas } = drawCanvasStore.useState();
  const { selectedAsset } = useSelectedAsset();

  return (
    <SizeObservableWrapper
      className="flex-fill w-full flex-col-center"
      onSizeChange={({ width, height }) => setMaxSize({ width, height })}
    >
      <BaseImageContainer image={selectedAsset?.content}>
        {/* FIXME : compute the wanted initial size to avoid image flickering */}
        {maxSize !== undefined && (
          <ImageEditor
            initWidth={10}
            initHeight={10}
            fabricCanvas={fabricCanvas}
            onCanvasReady={(canvas) => {
              canvas.on("object:added", drawCanvasStore.addObject);
              canvas.on("object:removed", drawCanvasStore.removeObject);
              canvas.on("history:append", drawCanvasStore.addActionHistory);
              canvas.on("history:clear", drawCanvasStore.clearActionHistory);
              canvas.on("history:redo", drawCanvasStore.addActionHistory);
              canvas.on("history:undo", drawCanvasStore.removeActionHistory);
              drawCanvasStore.setFabricCanvas(canvas);
            }}
            backgroundImageUrl={selectedAsset?.content.url}
            maxWidth={maxSize.width}
            maxHeight={maxSize.height}
          />
        )}
      </BaseImageContainer>
    </SizeObservableWrapper>
  );
};
