import { useParams } from "react-router";
import { useCachedQuery } from "../../../hooks/useCachedQuery.ts";
import { showDeletedAssetsStore } from "../stores/showDeletedAssetsStore.ts";
import type { Board } from "../types.ts";

export const useBoard = () => {
  const { showDeletedAssets } = showDeletedAssetsStore.useState();

  return {
    board: useCachedQuery<Board>({
      queryKey: `boards/${useParams()["boardUuid"]!}`,
      queryParamsKey: showDeletedAssets
        ? { include_deleted: showDeletedAssets.toString() }
        : undefined,
    }),
  };
};
