import { Image } from "../../../../../../components/Image/Image.tsx";
import type { ImageContent } from "../../../../types.ts";
import { BaseImageContainer } from "./BaseImageContainer.tsx";

export const SingleImageContainer = ({ image }: { image?: ImageContent }) => (
  <BaseImageContainer image={image}>
    {image === undefined ? null : (
      <Image
        className="max-h-full max-w-full"
        imageClassName="max-h-full max-w-full"
        src={image.url}
      />
    )}
  </BaseImageContainer>
);
