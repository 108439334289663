export const FAVORITE_TAG_NAME = "__favorite__";

export const IMAGE_GENERATION_MODEL_TYPE = [
  "text_to_image",
  "image_to_image",
  "upscaler",
  "generative_fill",
  "style_transfer",
] as const;

export const SCHEDULERS = [
  "DDIM",
  "DPMSolverMultistep",
  "HeunDiscrete",
  "KarrasDPM",
  "K_EULER_ANCESTRAL",
  "K_EULER",
  "PNDM",
];

export const SDXL_REFINERS = [
  "no_refiner",
  "base_image_refiner",
  "expert_ensemble_refiner",
];

export const GENERATION_RATIOS = [
  "r_1_1",
  "r_9_16",
  "r_3_4",
  "r_4_3",
  "r_16_9",
] as const;

export const NODE_STATUS = [
  "running",
  "succeeded",
  "failed",
  "not_running",
] as const;

export const QUALITY_PRESETS = ["fast", "high"] as const;
