import classNames from "classnames";
import { NavLink } from "react-router-dom";
import {
  ASSETS_WORKSPACE,
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
} from "../../../routes.ts";
import { applyCurrentSearchParamsToPath } from "../../../utils/url.ts";

export const WorkspaceMenu = () => (
  <div className="h-full flex-row gap-xl">
    <WorkspaceMenuButton to={CREATE_WORKSPACE} title="Create" />
    <WorkspaceMenuButton to={EDIT_WORKSPACE} title="Edit" />
    <WorkspaceMenuButton to={ASSETS_WORKSPACE} title="Assets" />
  </div>
);

const WorkspaceMenuButton = ({ to, title }: { to: string; title: string }) => (
  <NavLink
    to={applyCurrentSearchParamsToPath(to)}
    className={({ isActive }) =>
      classNames(
        "h-full px-md flex-row-center border-b-[3px] font-semibold",
        isActive
          ? "border-gray-600"
          : "text-gray-400 border-transparent hover:bg-gray-200",
      )
    }
  >
    {title}
  </NavLink>
);
