import { useEffect } from "react";
import { z } from "zod";
import { Form } from "../../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../../components/Form/FormContext.ts";
import { addNullInput } from "../../../../../../../utils/validations.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { scrollCreateWorkspaceGridToTop } from "../../../../AssetsWorkspace/utils/scrollCreateWorkspaceGridToTop.ts";
import { GenerationBar } from "../../../../components/GenerationBar/GenerationBar.tsx";
import { GenerationRatioPresetSection } from "../../../../components/SettingsMenu/./GenerationRatioPresetSection.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetWrappedSection } from "../../../../components/SettingsMenu/QualityPresetWrappedSection.tsx";
import { StyleIntensitySection } from "../../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { SwitchSection } from "../../../../components/SettingsMenu/SwitchSection.tsx";
import { TextSection } from "../../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../../components/SettingsMenuLayout.tsx";
import {
  useGenerateSettings,
  useGenerateSettingsAtom,
} from "../hooks/useGenerateSettings.ts";
import { useImageFromPromptGeneration } from "../hooks/useImageFromPromptGeneration.ts";
import { generatePromptStore } from "../stores/generatePromptStore.ts";

export const GenerateSettings = () => {
  const {
    mutation: generateImageFromPrompt,
    isLoading: isImageFromPromptGenerationLoading,
  } = useImageFromPromptGeneration({
    onSuccess: scrollCreateWorkspaceGridToTop,
  });

  return (
    <Form
      className="flex-col flex-fill"
      schema={zGenerateSettings}
      initialValues={{
        prompt: "",
        selectedStyleUuid: null,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        generateImageFromPrompt({
          prompt: values.prompt,
          styleUuid: values.selectedStyleUuid,
        });
        return Promise.resolve(true);
      }}
    >
      <GenerateSettingsFormContent
        isGenerationLoading={isImageFromPromptGenerationLoading}
      />
    </Form>
  );
};

const zGenerateSettings = z.object({
  // FIXME: use undefined instead of null for unselected selectedStyleUuid
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a prompt" }),
});
type StyleGenerateValues = z.input<typeof zGenerateSettings>;

export const GenerateSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const { generateSettings, defaultGenerateSettings } = useGenerateSettings();
  const { prompt } = generatePromptStore.useState();

  const { setValues, submit, useError } = useForm<StyleGenerateValues>();
  const missingStyleError = useError((v) => v.selectedStyleUuid);

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ prompt });
  }, [setValues, prompt]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <QualityPresetWrappedSection
            value={generateSettings.quality_preset}
            onChange={(preset) =>
              useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                quality_preset: preset,
              })
            }
          />
          <GenerationRatioPresetSection
            generationRatio={generateSettings.generation_ratio}
            onGenerationRatioChange={(generationRatio) =>
              useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                generation_ratio: generationRatio,
              })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  defaultLoraScale={defaultGenerateSettings.lora_scale}
                  loraScale={generateSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      lora_scale: loraScale,
                    })
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={generateSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      negative_prompt: negativePrompt,
                    })
                  }
                />
                <SwitchSection
                  switchName="Prompt enhancement"
                  switchInformationSection="When activated, your prompt will be automatically modified to improve your results. You can see your modified prompt by looking at the infos of your generated image."
                  value={generateSettings.prompt_enhancement}
                  onChange={(promptEnhancement: boolean) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      prompt_enhancement: promptEnhancement,
                    })
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={
        <GenerationBar
          isLoading={isGenerationLoading}
          onGenerate={submit}
          prompt={prompt}
          setPrompt={(newPrompt) => generatePromptStore.setPrompt(newPrompt)}
          promptError={useError((v) => v.prompt)}
          autoFocus
          extraErrors={missingStyleError ? [missingStyleError] : undefined}
          infoContent={
            <div className="flex-col gap-sm">
              <div className="font-semibold">To get the best results:</div>
              <ul className="pl-lg list-disc">
                <li>Write in English</li>
                <li>Use short words separated by commas</li>
                <li>
                  Respect the following prompt structure: subject, action,
                  background, other style attributes (lightning, camera
                  focus...)
                </li>
                <li>
                  Go to{" "}
                  <span className="font-semibold">Advanced &gt; Exclude</span>{" "}
                  to remove elements
                </li>
              </ul>
            </div>
          }
        />
      }
    />
  );
};
