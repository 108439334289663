import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { Progress } from "../../../components/Progress/Progress.tsx";
import { Switch } from "../../../components/Switch/Switch.tsx";
import { useOnMount } from "../../../hooks/useOnMount.ts";
import { pseudoRandomUUID } from "../../../utils/stackoverflow.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StyleCreationImageTile } from "../components/StyleCreationImageTile.tsx";
import { TipsSection } from "../components/TipsSection.tsx";
import { useAddImageOnStyle } from "../hooks/useAddImageOnStyle.ts";

export const TrainingImagesForm = ({
  widthClassName,
  style,
  onContinue,
  onGoBack,
  buttonLoading,
  buttonName,
  showExtraTrainingParams = false,
  showFluxTraining = false,
}: {
  widthClassName?: string;
  style: Style;
  buttonName: string;
  buttonLoading?: boolean;
  showExtraTrainingParams?: boolean;
  showFluxTraining?: boolean;
  onGoBack: () => void;
  onContinue: ({
    instancePrompt,
    conceptToken,
    numTrainEpochs,
    enableFluxTraining,
  }: {
    instancePrompt: string;
    conceptToken: string;
    numTrainEpochs: number | undefined;
    enableFluxTraining: boolean;
  }) => void;
}) => {
  const [uploadingImages, setUploadingImages] = useState<
    { fileToUpload: File; uuid: string }[]
  >([]);
  const [conceptToken, setConceptToken] = useState("TOK");
  const [instancePrompt, setInstancePrompt] = useState("In the style of TOK, ");
  const [numTrainEpochs, setNumTrainEpochs] = useState<number>();
  const [displayErrorTokenMessage, setDisplayErrorTokenMessage] =
    useState(false);
  const flags = useFlags();
  const [enableFluxTraining, setEnableFluxTraining] = useState(true);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      setUploadingImages(
        uploadingImages.concat(
          acceptedFiles.map((file) => ({
            uuid: pseudoRandomUUID(),
            fileToUpload: file,
          })),
        ),
      );
    },
  });

  const hasNotEnoughImages = useMemo(
    () => style.training_images.length < (flags["customTraining"] ? 1 : 4),
    [style, flags],
  );

  const uploadButtonRef = useRef<HTMLDivElement>(null);
  return (
    // FIXME: find a way to extract the fullscreen drop zone for better modularisation
    <div className="relative flex-col-center flex-fill" {...getRootProps()}>
      <ModelCreationBackButton
        onClick={onGoBack}
        className="absolute top-16 left-16"
      />
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <div className="flex-col w-full flex-fill pt-[80px] pb-[30px] gap-[40px] overflow-y-auto items-center">
        <div className="flex-col gap-xl">
          <div className="flex-col items-center">
            <span className="text-[38px] text-gray-900 font-burg text-center">
              upload at least 10 pictures to create your model
            </span>
            <span className="text-xl text-gray-400">
              You can upload less, but no wow effect guaranteed!
            </span>
          </div>
        </div>
        <TipsSection styleType={style.type ? style.type : undefined} />
        {showFluxTraining && (
          <div className={classNames("flex-row gap-md", widthClassName)}>
            <div className="font-semibold">Use Pimento v2</div>
            <Switch
              value={enableFluxTraining}
              onChange={setEnableFluxTraining}
            />
          </div>
        )}
        {showExtraTrainingParams && (
          <div className={classNames("flex-col gap-md", widthClassName)}>
            <div className="flex-col gap-sm">
              <span className="font-semibold">Concept token</span>
              <input
                type="text"
                className="p-xl rounded-sm border-[1px] border-gray-300 text-gray-500 placeholder:text-gray-300"
                placeholder="TOK"
                value={conceptToken}
                onChange={(event) => {
                  setConceptToken(event.target.value);
                }}
              />
            </div>
            <div className="flex-col gap-sm">
              <span className="font-semibold">Training prompt prefix</span>
              <input
                type="text"
                className="p-xl rounded-sm border-[1px] border-gray-300 text-gray-500 placeholder:text-gray-300"
                placeholder="In the style of TOK, "
                value={instancePrompt}
                onChange={(event) => {
                  setInstancePrompt(event.target.value);
                }}
              />
            </div>
            <div className="flex-col gap-sm">
              <span className="font-semibold">Number of epochs</span>
              <input
                type="number"
                className="p-xl rounded-sm border-[1px] border-gray-300 text-gray-500 placeholder:text-gray-300"
                placeholder="Epochs"
                value={numTrainEpochs}
                onChange={(event) => {
                  setNumTrainEpochs(Number(event.target.value));
                }}
              />
            </div>
            <span className="h-14 text-red-700">
              {displayErrorTokenMessage
                ? "The training prompt prefix must contain the concept token"
                : ""}
            </span>
          </div>
        )}
        <div className={classNames("flex-col gap-2xl", widthClassName)}>
          {(style.training_images.length > 0 || uploadingImages.length > 0) && (
            <div className="flex-shrink overflow-y-auto">
              <div className="grid lg:grid-cols-3 2xl:grid-cols-4 justify-items-center gap-2xl min-w-[120px] w-full">
                {uploadingImages.map((uploadingImage): JSX.Element => {
                  const clearAndScrollToBottom = () => {
                    setUploadingImages((current) =>
                      current.filter((d) => d.uuid !== uploadingImage.uuid),
                    );
                    uploadButtonRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  };
                  return (
                    <TrainingImage
                      key={uploadingImage.uuid}
                      file={uploadingImage.fileToUpload}
                      styleUuid={style.uuid}
                      onUploaded={clearAndScrollToBottom}
                    />
                  );
                })}
                {style.training_images.map((image) => (
                  <StyleCreationImageTile
                    key={image.uuid}
                    innerClassName="rounded-sm"
                    className="aspect-square h-[132px] w-[132px]"
                    image={image}
                    styleUuid={style.uuid}
                  />
                ))}
              </div>
            </div>
          )}
          {style.training_images.length > 0 &&
            style.training_images.length < 10 && (
              <div className="flex-row items-center gap-md self-start font-semibold">
                <Icon
                  name="Info"
                  className="mb-2 stroke-pimento-blue stroke-xl"
                  size={16}
                />
                <span className="text-pimento-blue">
                  More than 10 pictures lead to better results
                </span>
              </div>
            )}
        </div>
        <div
          ref={uploadButtonRef}
          className={classNames("flex-grow max-h-[250px]", widthClassName)}
        >
          <input {...getInputProps()} />
          <button
            type="button"
            className="h-full w-full flex-col-center gap-xl py-[37px] px-[30px] rounded-md border border-dashed border-pimento-blue bg-pimento-blue bg-opacity-5"
            onClick={() => open()}
          >
            <Icon
              name="ImagePlus"
              size={28}
              className="stroke-gray-900 stroke-[1.2px]"
            />
            <span className="text-lg font-semibold text-gray-900">
              <span className="text-pimento-blue underline">Browse files</span>
              &nbsp;or drop images
            </span>
          </button>
        </div>
        <Button
          className={widthClassName}
          disabled={hasNotEnoughImages}
          onClick={() => {
            if (instancePrompt.includes(conceptToken)) {
              setDisplayErrorTokenMessage(false);
              onContinue({
                instancePrompt,
                conceptToken,
                numTrainEpochs,
                enableFluxTraining,
              });
            } else {
              setDisplayErrorTokenMessage(true);
            }
          }}
          loading={buttonLoading}
        >
          {hasNotEnoughImages ? "Upload at least 4 images" : buttonName}
        </Button>
      </div>
    </div>
  );
};

const TrainingImage = ({
  file,
  styleUuid,
  onUploaded,
  onSuccess,
}: {
  file: File;
  styleUuid: string;
  onUploaded: () => void;
  onSuccess?: (image_uuid: string) => void;
}) => {
  const [src] = useState(() => URL.createObjectURL(file));
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { mutate: uploadImage, progress } = useAddImageOnStyle({
    styleUuid,
    onUploaded,
    onSuccess,
  });

  useOnMount(() => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (blob) => {
        uploadImage({ image: blob as Blob });
      },
      "blob",
    );
  });

  return (
    <div className="rounded overflow-hidden aspect-square h-[132px] w-[132px]">
      <Image
        src={src}
        className="opacity-60 h-full w-full"
        imageClassName="m-auto object-cover object-center"
        onLoad={() => setIsImageLoaded(true)}
      />
      {isImageLoaded && <Progress value={progress} />}
    </div>
  );
};
