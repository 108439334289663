import classNames from "classnames";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { imageStripeDisplayStore } from "../stores/imageStripeDisplayStore.ts";
import { AssetActions } from "./AssetActions.tsx";
import { ImageViewModeButtons } from "./ImageViewModeButtons.tsx";

export const ActionsBar = () => {
  const { isImageStripeOpen } = imageStripeDisplayStore.useState();
  const { selectedAsset: asset } = useSelectedAsset();

  return (
    <div className="h-3xl px-md py-sm flex-row items-center bg-white justify-between">
      {asset ? (
        <>
          <ImageViewModeButtons asset={asset} />
          <AssetActions asset={asset} />
        </>
      ) : (
        <div /> // Placeholder to keep the layout consistent.
      )}
      <ClickableIcon
        name="ImageStripe"
        iconClassName={classNames(
          isImageStripeOpen
            ? "fill-gray-600 stroke-gray-600 hover:fill-gray-350 hover:stroke-gray-350"
            : "fill-gray-350 stroke-gray-350 hover:fill-gray-600 hover:stroke-gray-600",
        )}
        tooltip={{
          side: "top",
          content: isImageStripeOpen
            ? "Close the image stripe"
            : "Open the image stripe",
        }}
        onClick={() => {
          imageStripeDisplayStore.toggleImageStripe();
        }}
      />
    </div>
  );
};
