export const OnboardingCharacterAngle1 = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.75" y="0.75" width="69.5" height="69.5" rx="4.25" />
    <path
      d="M34.5007 27.2992C36.1805 27.2992 37.5423 25.9337 37.5423 24.2492C37.5423 22.5648 36.1805 21.1992 34.5007 21.1992C32.8208 21.1992 31.459 22.5648 31.459 24.2492C31.459 25.9337 32.8208 27.2992 34.5007 27.2992Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.375 69.9992L34.5 51.6992L43.625 69.9992"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 33.3984L34.5 39.4984L52.75 33.3984"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M34.5 39.5V51.7" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
