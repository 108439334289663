import classNames from "classnames";
import { Link } from "react-router-dom";
import { Animation } from "../../../components/Animation/Animation.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";

export const StyleInitSplashScreen = ({
  widthClassName,
}: {
  widthClassName: string;
}) => (
  <div className="relative flex-col-center flex-fill w-full overflow-y-auto">
    <ModelCreationCloseButton className="absolute top-16 right-16" />
    <div
      className={classNames(
        "flex-fill w-full flex-col pt-[80px] pb-[30px] gap-[40px] items-center",
        widthClassName,
      )}
    >
      <div className="flex-col items-center">
        <span className="text-[38px] text-gray-900 font-burg">
          model in creation...
        </span>
        <span className="text-xl text-gray-400">
          It takes on average 15 minutes. You'll be notified by email.
        </span>
        <div
          style={{ height: 327, width: 324 }}
          className="flex-fill flex-col-center"
        >
          <Animation
            loadData={() => import("../../../assets/boardInitLoading.json")}
            loop
            autoplay
          />
        </div>
      </div>

      <Link to="/">
        <span className="hover:opacity-70 underline underline-offset-4">
          Home
        </span>
      </Link>
    </div>
  </div>
);
