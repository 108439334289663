import { useQueryClient } from "@tanstack/react-query";

export const useCachedQuery = <T>({
  queryKey,
  queryParamsKey,
}: {
  queryKey: string;
  queryParamsKey?: { [x: string]: string };
}) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<T>(
    queryParamsKey ? [queryKey, queryParamsKey] : [queryKey],
  );
  if (!data) throw new Error(`Data is not loaded yet for query ${queryKey}`);
  return data;
};
