export const STYLE_IMAGES_KIND = [
  "photography",
  "illustration_2d",
  "graphic_3d",
  "icon",
  "other",
];

export const STYLE_COLOR_KIND = [
  "black_and_white",
  "monochrome",
  "bland",
  "regular",
  "bright",
  "other",
];

export const STYLE_TYPES = ["style", "object", "character"] as const;

export const GENERIC_STYLE_UUID =
  "0025e1b0-c447-4d92-b1ee-487a098fea54" as const;
