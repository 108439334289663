import { Route } from "react-router";
import { Routes } from "react-router-dom";
import {
  DRAW_TOOL_PATH,
  GENERATE_TOOL_PATH,
  SELECT_TOOL_PATH,
  TRANSFER_TOOL_PATH,
  UPSCALE_TOOL_PATH,
  VARIATION_TOOL_PATH,
} from "../../../routes.ts";
import { StyleSelectionButtonSection } from "../Workspaces/components/SettingsMenu/StyleSelectionButtonSection.tsx";
import { GenerateSettings } from "../Workspaces/CreateWorkspace/Tools/GenerateView/Settings/GenerateSettings.tsx";
import { StyleTransferSettings } from "../Workspaces/CreateWorkspace/Tools/StyleTransferView/StyleTransferSettings.tsx";
import { GenerativeFillSettings } from "../Workspaces/EditWorkspace/Tools/GenerativeFillView/GenerativeFillSettings.tsx";
import { MagicDrawSettings } from "../Workspaces/EditWorkspace/Tools/MagicDrawView/MagicDrawSettings.tsx";
import { UpscaleSettings } from "../Workspaces/EditWorkspace/Tools/UpscaleView/UpscaleSettings.tsx";
import { VariationSettings } from "../Workspaces/EditWorkspace/Tools/VariationView/VariationSettings.tsx";

export const SettingsMenu = () => (
  <div className="flex-col h-full w-[270px] border-r bg-white">
    <StyleSelectionButtonSection />
    <Routes>
      <Route path={GENERATE_TOOL_PATH} element={<GenerateSettings />} />
      <Route path={TRANSFER_TOOL_PATH} element={<StyleTransferSettings />} />
      <Route path={SELECT_TOOL_PATH} element={<GenerativeFillSettings />} />
      <Route path={DRAW_TOOL_PATH} element={<MagicDrawSettings />} />
      <Route path={VARIATION_TOOL_PATH} element={<VariationSettings />} />
      <Route path={UPSCALE_TOOL_PATH} element={<UpscaleSettings />} />
    </Routes>
  </div>
);
