import { Icon } from "../../../components/Icon/Icon.tsx";
import { EmbeddedImage } from "../../../components/Image/EmbeddedImage.tsx";
import type { StyleType } from "../../types.ts";

export const TipsSection = ({ styleType }: { styleType?: StyleType }) => (
  <div className="flex-row flex-wrap justify-center gap-[44px] items-center text-gray-400 text-lg font-semibold">
    {styleType === "style" ? (
      <div className="flex-col gap-md items-center">
        <div className="flex-row gap-xl">
          <div className="relative">
            <div className="flex-row rounded-md overflow-hidden">
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_2.png")}
                height={50}
                width={50}
              />
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_1.png")}
                height={50}
                width={50}
              />
            </div>
            <Icon
              name="Valid"
              className="absolute -right-[8px] -bottom-[8px] stroke-gray-400 stroke-md"
            />
          </div>
          <div className="relative ">
            <div className="flex-row rounded-md overflow-hidden">
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_2.png")}
                height={50}
                width={50}
              />
              <EmbeddedImage
                loadData={() => import("../assets/image_illustration_3.png")}
                height={50}
                width={50}
              />
            </div>
            <Icon
              name="Invalid"
              className="absolute -right-[8px] -bottom-[8px] stroke-gray-400 stroke-md"
            />
          </div>
        </div>
        <span>Same style</span>
      </div>
    ) : styleType === "object" ? (
      <div className="flex-col items-center gap-md">
        <div className="flex-row gap-xl items-end">
          <Icon
            name="OnboardingObjectAngle1"
            className="stroke-gray-400 stroke-xl"
          />
          <Icon
            name="OnboardingObjectAngle2"
            className="stroke-gray-400 stroke-xl"
          />
        </div>
        <span>Different angles</span>
      </div>
    ) : styleType === "character" ? (
      <div className="flex-col items-center gap-md">
        <div className="flex-row gap-xl items-end">
          <Icon
            name="OnboardingCharacterAngle1"
            className="stroke-gray-400 stroke-xl"
          />
          <Icon
            name="OnboardingCharacterAngle2"
            className="stroke-gray-400 stroke-xl"
          />
        </div>
        <span>Different angles</span>
      </div>
    ) : null}
    <div className="flex-col items-center gap-md">
      <div className="flex-row gap-xl items-end">
        <Icon name="OnboardingSquareFormat" className="stroke-gray-400" />
        <Icon name="OnboardingVerticalFormat" className="stroke-gray-400" />
        <Icon name="OnboardingHorizontalFormat" className="stroke-gray-400" />
      </div>
      <span>Squared</span>
    </div>
    <div className="flex-col gap-md items-center">
      <Icon name="OnboardingNoTextTip" className="stroke-gray-400" />
      <span>No text</span>
    </div>
    <div className="flex-col gap-md items-center">
      <div className="flex-row gap-xl">
        <Icon name="OnboardingCenteredTip" className="stroke-gray-400" />
        <Icon name="OnboardingNonCenteredTip" className="stroke-gray-400" />
      </div>
      <span>Centered</span>
    </div>
    <div className="flex-col gap-md items-center">
      <Icon name="OnboardingSizeTip" className="stroke-gray-400" />
      <span>Min 768px</span>
    </div>
  </div>
);
