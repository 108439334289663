import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { DRAW_TOOL, SELECT_TOOL } from "../../../../routes.ts";
import { GenerativeFillToolbar } from "./Tools/GenerativeFillView/GenerativeFillToolbar.tsx";
import { DrawToolbar } from "./Tools/MagicDrawView/DrawToolbar.tsx";

export const ToolBarContent = () => (
  <div className="w-full h-[48px]">
    <Routes>
      <Route
        path={SELECT_TOOL}
        element={
          <div className="w-full h-full flex-row-center px-md bg-white border-r border-b">
            <GenerativeFillToolbar />
          </div>
        }
      />
      <Route
        path={DRAW_TOOL}
        element={
          <div className="w-full h-full flex-row-center px-md bg-white border-r border-b">
            <DrawToolbar />
          </div>
        }
      />
    </Routes>
  </div>
);
