import classNames from "classnames";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";

export const ModelCreationBackButton = ({
  className,
  onClick,
}: {
  className: string;
  onClick: () => void;
}) => (
  <BaseButton
    className={classNames(
      "rounded-sm border border-gray-300 bg-white text-gray-600",
      className,
    )}
    onClick={onClick}
  >
    <Icon
      name="ArrowLeft"
      size={32}
      className="p-md rounded-sm bg-white text-gray-600 hover:bg-gray-200"
    />
  </BaseButton>
);
