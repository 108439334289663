import { createStore } from "../../../../../../../utils/createStore.ts";

export const styleTransferBaseImageStore = createStore(
  {
    styleTransferBaseImageUuid: undefined as string | undefined,
  },
  ({ set }) => ({
    setStyleTransferBaseImage: ({
      styleTransferBaseImageUuid,
    }: {
      styleTransferBaseImageUuid: string | undefined;
    }) =>
      set({
        styleTransferBaseImageUuid,
      }),
    resetStyleTransferBaseImage: () => {
      set({
        styleTransferBaseImageUuid: undefined,
      });
    },
  }),
);
