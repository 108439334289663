import type { ReactNode } from "react";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { useUser } from "../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../types.ts";
import type { Style } from "../../../../types.ts";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { useOpenStyleImageModal } from "../../../ImageModal/useOpenStyleImageModal.ts";
import type { BoardImageContent } from "../../../types.ts";
import { AssetTagsSection } from "./AssetTagsSection.tsx";
import { ImageDebugInformation } from "./ImageDebugInformation.tsx";
import { ImageGenerationParameters } from "./ImageGenerationParameters.tsx";
import { InformationHeader } from "./InformationHeader.tsx";

export const InformationMenu = () => {
  const { user } = useUser();
  const { selectedAsset: asset } = useSelectedAsset();

  if (!asset) return null;

  return (
    <div className="flex-col w-[270px] border-l bg-white">
      <div className="flex-row h-[50px] items-center gap-md w-full px-lg py-md border-b">
        <Icon name="Info" size={16} />
        <span className="font-semibold">Info</span>
      </div>
      <div className="flex-col flex-shrink overflow-y-scroll">
        <div className="flex-col w-full gap-md p-lg border-b">
          <div className="font-semibold">Tags</div>
          <AssetTagsSection asset={asset} />
        </div>
        <InformationSection>
          <InformationHeader image={asset.content} />
        </InformationSection>
        <DeletionInformationSection />
        {asset.content.generation_model_type !== null && (
          <StyleInformationSection image={asset.content} />
        )}
        <PromptInformationSection image={asset.content} />
        <NegativePromptInformationSection image={asset.content} />
        <InitImageInformationSection image={asset.content} />
        {asset.content.generation_model_type !== null && (
          <InformationSection>
            <ImageGenerationParameters image={asset.content} />
          </InformationSection>
        )}
        {user && isUserAllowedTo(user, "mode:debug") && (
          <InformationSection>
            <ImageDebugInformation image={asset.content} />
          </InformationSection>
        )}
      </div>
    </div>
  );
};

const StyleInformationSection = ({ image }: { image: BoardImageContent }) => {
  const { openStyleImageModal } = useOpenStyleImageModal();

  const loraScale = image.generation_model_params?.lora_scale;

  const { data: style, isLoading } = useAppQuery<Style>({
    queryKey: `styles/${image.generation_model_params?.style_uuid}`,
    enabled: image.generation_model_params?.style_uuid !== undefined,
  });

  return isLoading || style ? (
    <InformationSection>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex-col gap-md">
          <div className="flex-col gap-sm">
            <div className="flex-row justify-between">
              <span className="font-semibold">Style</span>
              <span className="w-[70%] truncate">{style.name}</span>
            </div>
            <div className="grid grid-cols-4 gap-md w-fit">
              {style.training_images.slice(0, 4).map((it) => (
                <div
                  key={it.uuid}
                  className="relative group max-h-[60px] max-w-[60px] aspect-square"
                >
                  <Image
                    key={it.uuid}
                    className="h-full w-full aspect-square"
                    imageClassName="h-full w-full object-cover object-center rounded-sm"
                    src={it.url}
                  />
                  <ClickableIcon
                    name="Expand"
                    size={10}
                    tooltip={{ side: "left", content: "View full size" }}
                    className="absolute top-4 right-4 invisible group-hover:visible tile-action"
                    onClick={(event) => {
                      event.stopPropagation();
                      openStyleImageModal(style.uuid, it.uuid, true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex-col">
            {loraScale ? (
              <div className="flex-row justify-between items-center">
                <span>Influence</span>
                <span>{Math.round(loraScale * 100)}%</span>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </InformationSection>
  ) : null;
};

const PromptInformationSection = ({ image }: { image: BoardImageContent }) => {
  const userPrompt = image.generation_model_params?.user_prompt;

  return (
    userPrompt !== undefined && (
      <InformationSection>
        <div className="flex-col gap-md">
          <span className="font-semibold">Prompt</span>
          <span>{userPrompt ? userPrompt : "No prompt used"}</span>
        </div>
      </InformationSection>
    )
  );
};

const NegativePromptInformationSection = ({
  image,
}: {
  image: BoardImageContent;
}) => {
  const negativePrompt = image.generation_model_params?.negative_prompt;

  return (
    negativePrompt !== undefined && (
      <InformationSection>
        <div className="flex-col gap-md">
          <span className="font-semibold">Exclude</span>
          <span>{negativePrompt ? negativePrompt : "Nothing"}</span>
        </div>
      </InformationSection>
    )
  );
};

const InitImageInformationSection = ({
  image,
}: {
  image: BoardImageContent;
}) => {
  const initImageUuid =
    image.generation_model_type === "upscaler" ||
    image.generation_model_type === "generative_fill" ||
    image.generation_model_type === "style_transfer" ||
    image.generation_model_type === "image_to_image"
      ? image.generation_model_params.init_image_uuid
      : undefined;

  const { data: initImageData } = useAppQuery<{ url: string }>({
    queryKey: `contents/${initImageUuid}`,
    enabled: !!initImageUuid,
  });

  return (
    initImageData?.url && (
      <InformationSection>
        <div className="flex-col items-start gap-xs">
          <span className="font-semibold">Initial image</span>
          <div className="flex-row-center w-full">
            <Image
              src={initImageData.url}
              className="max-w-full"
              imageClassName="h-full w-full object-cover object-center"
            />
          </div>
        </div>
      </InformationSection>
    )
  );
};

const DeletionInformationSection = () => {
  const { selectedAsset } = useSelectedAsset();
  if (!selectedAsset) return null;

  const deletedAt = selectedAsset.deleted_at;

  return (
    deletedAt !== null && (
      <InformationSection>
        <div className="flex-row justify-between">
          <span className="font-semibold text-pimento-red">Deleted at</span>
          <span className="text-pimento-red">
            {new Date(deletedAt).toLocaleString()}
          </span>
        </div>
      </InformationSection>
    )
  );
};

const InformationSection = ({ children }: { children: ReactNode }) => (
  <div className="flex-col gap-md p-lg border-b">{children}</div>
);
