import { type ReactNode, useState } from "react";
import { Icon } from "../../../../../../components/Icon/Icon.tsx";
import { SizeObservableWrapper } from "../../../../../../components/SizeObservableWrapper/SizeObservableWrapper.tsx";
import { Spinner } from "../../../../../../components/Spinner/Spinner.tsx";
import { ImageDropZone } from "../../../../components/ImageDropZone/ImageDropZone.tsx";
import { LoadingTile } from "../../../../components/LoadingTile.tsx";
import { useAddImageOnBoard } from "../../../../hooks/useAddImageOnBoard.ts";
import { useSelectedAssetUuid } from "../../../../hooks/useSelectedAssetUuid.ts";
import type { ImageContent } from "../../../../types.ts";

// FIXME: Merge BaseImageContainer with SingleImageContainer
export const BaseImageContainer = ({
  image,
  children,
}: {
  image?: ImageContent;
  children?: ReactNode;
}) =>
  image === undefined ? (
    <div className="h-[400px] aspect-square">
      <EmptyImageContainer />
    </div>
  ) : image.status === "processing" ? (
    <SquareLoadingImageContainer image={image} />
  ) : (
    <div className="flex-row justify-center max-h-full max-w-full">
      {children}
    </div>
  );
const EmptyImageContainer = () => {
  const { setSelectedAssetUuid } = useSelectedAssetUuid();
  const { mutate: uploadImage, isLoading } = useAddImageOnBoard({
    // eslint-disable-next-line
    onUploaded: (asset_uuid) => {
      setSelectedAssetUuid(asset_uuid);
    },
  });

  return isLoading ? (
    <div className="h-full w-full bg-white flex-col-center items-center">
      <Spinner />
    </div>
  ) : (
    <ImageDropZone
      onDrop={(file) => {
        if (file) {
          uploadImage({
            image: file.fileToUpload,
          });
        }
      }}
      content={
        <>
          <Icon name="ImagePlus" size={32} className="stroke-pimento-blue" />
          <span className="text-lg font-semibold">
            Drag and drop an image or &nbsp;
            <span className="text-lg text-pimento-blue underline">
              browse files
            </span>
          </span>
        </>
      }
    />
  );
};

const SquareLoadingImageContainer = ({ image }: { image: ImageContent }) => {
  const [wrapperSize, setWrapperSize] = useState<{
    width: number;
    height: number;
  }>();

  return (
    <SizeObservableWrapper
      className="flex-fill w-full flex-col-center"
      onSizeChange={({ width, height }) => setWrapperSize({ width, height })}
    >
      {wrapperSize !== undefined && (
        <div
          style={{
            width: Math.min(wrapperSize.width, wrapperSize.height),
            height: Math.min(wrapperSize.width, wrapperSize.height),
          }}
        >
          <LoadingTile image={image} className="w-full h-full" />
        </div>
      )}
    </SizeObservableWrapper>
  );
};
