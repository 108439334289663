import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style } from "../../types.ts";
import { TrainingImagesForm } from "./TrainingImagesForm.tsx";

export const StyleTrainingImagesForm = ({
  widthClassName,
  style,
  onContinue,
}: {
  widthClassName?: string;
  style: Style;
  onContinue: () => void;
}) => {
  const trainStyle = useAppMutation({
    path: "styles/train",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => onContinue(),
  }).mutation;

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  const flags = useFlags();

  return (
    <TrainingImagesForm
      style={style}
      onContinue={({
        instancePrompt,
        conceptToken,
        numTrainEpochs,
        enableFluxTraining,
      }) =>
        trainStyle.mutate({
          uuid: style.uuid,
          instance_prompt: instancePrompt,
          concept_token: conceptToken,
          num_train_epochs: numTrainEpochs,
          flux_training_enabled: enableFluxTraining,
        })
      }
      onGoBack={
        // FIXME: better structure the form to avoid doing a mutation here
        () => {
          updateStyle({
            uuid: style.uuid,
            type: null,
          });
        }
      }
      buttonLoading={trainStyle.isLoading}
      widthClassName={widthClassName}
      showExtraTrainingParams={flags["customTraining"]}
      showFluxTraining
      buttonName="Create my model"
    />
  );
};
