export const OnboardingObjectAngle1 = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.75" y="0.75" width="69.5" height="69.5" rx="4.25" />
    <path
      d="M50.375 45.125H54.625C55.9 45.125 56.75 44.275 56.75 43V36.625C56.75 34.7125 55.2625 33.0125 53.5625 32.5875C49.7375 31.525 44 30.25 44 30.25C44 30.25 41.2375 27.275 39.325 25.3625C38.2625 24.5125 36.9875 23.875 35.5 23.875H20.625C19.35 23.875 18.2875 24.725 17.65 25.7875L14.675 31.95C14.3936 32.7707 14.25 33.6324 14.25 34.5V43C14.25 44.275 15.1 45.125 16.375 45.125H20.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.875 49.375C27.2222 49.375 29.125 47.4722 29.125 45.125C29.125 42.7778 27.2222 40.875 24.875 40.875C22.5278 40.875 20.625 42.7778 20.625 45.125C20.625 47.4722 22.5278 49.375 24.875 49.375Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.125 45.125H41.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.125 49.375C48.4722 49.375 50.375 47.4722 50.375 45.125C50.375 42.7778 48.4722 40.875 46.125 40.875C43.7778 40.875 41.875 42.7778 41.875 45.125C41.875 47.4722 43.7778 49.375 46.125 49.375Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
