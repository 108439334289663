import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useBoard } from "./useBoard.ts";
import { useUploadImage } from "./useUploadImage.ts";

export const useAddImageOnBoard = ({
  onUploaded,
  onSuccess,
}: {
  onUploaded?: (asset_uuid: string) => void;
  onSuccess?: (asset_uuid: string) => void;
}) => {
  const { board } = useBoard();
  const {
    mutation: { mutate: linkToBoard },
  } = useAppMutation({
    path: "boards/add-asset",
    invalidate: ["boards", `boards/${board.uuid}`],
    onCacheUpdated: (response: { data: { asset_uuid: string } }) => {
      onUploaded?.(response.data.asset_uuid);
    },
    onSuccess: (response: { data: { asset_uuid: string } }) =>
      onSuccess?.(response.data.asset_uuid),
  });

  const {
    mutate: upload,
    isLoading,
    progress,
  } = useUploadImage({
    onSuccess: (contentUuid) =>
      linkToBoard({
        content_uuid: contentUuid,
        board_uuid: board.uuid,
      }),
  });

  return {
    mutate: ({ image }: { image: Blob }) => {
      upload({ image });
    },
    isLoading,
    progress,
  };
};
