import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { EditableText } from "../../../components/EditableText/EditableText.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import { useUserCanOpenStyleTagMenu } from "../hooks/useUserCanOpenStyleTagMenu.ts";
import type { Style } from "../types.ts";
import { StyleExtraMenu } from "./StyleExtraMenu.tsx";

export const StyleTile = ({
  style,
  onClick,
  isLoading,
  disabled,
  isEditable = true,
}: {
  style: Style;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isEditable?: boolean;
}) => {
  const { user } = useUser();
  const userCanOpenStyleTagMenu = useUserCanOpenStyleTagMenu();

  const { mutate: nameMutate, isLoading: nameIsloading } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  return (
    <div
      className="group relative flex-col-center gap-lg rounded-sm"
      style={{ width: 180 }}
    >
      <BaseButton
        className="w-full"
        style={{ height: 220 }}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
      >
        <div className="relative group w-full h-full">
          {style.thumbnail_url ? (
            <Image
              src={style.thumbnail_url}
              className="h-full w-full rounded-xs"
              imageClassName="h-full w-full object-cover object-center group-hover:scale-110"
              loading="lazy"
            />
          ) : (
            <div className="h-full w-full rounded-xs overflow-clip">
              {style.uuid === GENERIC_STYLE_UUID ? (
                <div className="flex-col-center h-full w-full bg-pimento-blue group-hover:scale-110">
                  <Icon
                    size={70}
                    name="PimentoStar"
                    className="stroke-transparent fill-white"
                  />
                </div>
              ) : (
                <div className="flex-col-center h-full w-full bg-gray-100" />
              )}
            </div>
          )}
          {["created", "training"].includes(style.status) ? (
            <div className="absolute left-0 top-0 w-full h-full bg-white/90 flex-row-center text-gray-350 font-semibold text-xl">
              {style.status === "created" ? "Draft" : "Training"}
            </div>
          ) : null}
          <div className="absolute left-0 bottom-0 w-full h-full bg-gradient-to-t from-gray-600 to-20%" />
        </div>
      </BaseButton>
      <div className="absolute bottom-0 w-[90%] h-3xl flex-row items-center">
        {isEditable ? (
          <EditableText
            text={style.name ?? "Untitled"}
            onTextChange={(name) => nameMutate({ uuid: style.uuid, name })}
            className="h-full flex-row items-center"
            textClassName="text-white text-lg font-semibold"
            inputClassName="bg-transparent rounded text-lg font-semibold text-white"
            isLoading={nameIsloading}
          />
        ) : (
          <span className="w-full h-full flex-row items-center truncate text-lg font-semibold text-white">
            {style.name ?? "Untitled"}
          </span>
        )}
      </div>
      {user &&
        (isUserAllowedTo(user, "styles:delete") ||
          isUserAllowedTo(user, "styles:curate") ||
          userCanOpenStyleTagMenu) && (
          <div className="absolute right-sm top-sm">
            <StyleExtraMenu
              style={style}
              className="invisible group-hover:visible"
            />
          </div>
        )}
    </div>
  );
};
