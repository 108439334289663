import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { PresetButton } from "../../../components/PresetButton.tsx";
import { GENERATION_RATIOS } from "../../../constants.ts";
import type { GenerationRatio } from "../../../types.ts";
import { SettingsSectionWrapper } from "./SettingsSectionWrapper.tsx";

const generationRatioToIcon: Record<
  GenerationRatio,
  "Square" | "RectangleVertical" | "RectangleHorizontal"
> = {
  r_1_1: "Square",
  r_9_16: "RectangleVertical",
  r_3_4: "RectangleVertical",
  r_4_3: "RectangleHorizontal",
  r_16_9: "RectangleHorizontal",
};

export const GenerationRatioPresetSection = ({
  generationRatio,
  onGenerationRatioChange,
}: {
  generationRatio: GenerationRatio;
  onGenerationRatioChange: (generationRatio: GenerationRatio) => void;
}) => (
  <SettingsSectionWrapper
    name="Ratio"
    content={
      <div className="grid grid-cols-3 gap-sm">
        {GENERATION_RATIOS.map((preset) => (
          <PresetButton
            key={preset}
            isSelected={generationRatio === preset}
            onClick={() => onGenerationRatioChange(preset)}
          >
            <div className="flew-row grid grid-cols-2 gap-sm items-center">
              <Icon
                name={generationRatioToIcon[preset]}
                size={14}
                className="stroke-lg"
              />
              <div className="text-left">
                {preset.replace("r_", "").replace("_", ":")}
              </div>
            </div>
          </PresetButton>
        ))}
      </div>
    }
  />
);
