import { createContext, useContext } from "react";
import type { Store } from "../../utils/createStore.ts";

export type FormError = { path: string; message: string };
export type FormState<Values extends Record<string, unknown>> = {
  // forwarded props
  disabled: boolean;
  // state
  isSubmitting: boolean;
  submitted: boolean;
  dirty: boolean;
  values: Values;
  initialValues: Values;
  errors: FormError[];
};

export type FormStore<Values extends Record<string, unknown>> = Store<
  FormState<Values>,
  FormActions<Values>
>;

export type FormValue =
  | string
  | number
  | Date
  | boolean
  | null
  | undefined
  | FormValue[]
  | { [key: string]: FormValue };

export type FormActions<Values extends Record<string, unknown>> = {
  getError: (
    cb: (proxy: Values) => unknown,
    opts?: { force?: boolean; partial?: boolean },
  ) => string | undefined;
  useError: (
    cb: (proxy: Values) => unknown,
    opts?: { force?: boolean; partial?: boolean },
  ) => string | undefined;
  useValue: <Selection = Values>(
    selector?: (values: Values) => Selection,
    shallow?: true,
  ) => Selection;
  setValues: (values: Partial<Values> | ((draft: Values) => undefined)) => void;
  reset: () => void;
  submit: (nativeSubmit?: true) => void;
};

export const FormContext = createContext<FormStore<
  Record<string, unknown>
> | null>(null);
FormContext.displayName = "FormContext";

export const useForm = <Values extends Record<string, unknown>>() => {
  const context = useContext(FormContext);
  if (!context) throw new Error("FormProvider not available");
  return context as unknown as FormStore<Values>;
};
