import classNames from "classnames";
import type { ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { notifier } from "../../../../utils/notifier.ts";
import { pseudoRandomUUID } from "../../../../utils/stackoverflow.ts";

const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });

export const ImageDropZone = ({
  onDrop,
  content,
  className,
  minImageSideDimension = 256,
}: {
  onDrop: (file: { fileToUpload: File; uuid: string } | undefined) => void;
  content: ReactNode;
  className?: string;
  minImageSideDimension?: number;
}) => {
  const {
    getRootProps,
    getInputProps,
    open: openFileBrowser,
  } = useDropzone({
    multiple: false,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: (acceptedFile) => {
      void loadImage(URL.createObjectURL(acceptedFile[0])).then((image) => {
        if (
          image.width < minImageSideDimension ||
          image.height < minImageSideDimension
        ) {
          notifier.error(
            `Your image dimensions should be greater than ${minImageSideDimension}x${minImageSideDimension} pixels`,
          );
        } else {
          onDrop({
            uuid: pseudoRandomUUID(),
            fileToUpload: acceptedFile[0],
          });
        }
      });
    },
  });

  return (
    <div {...getRootProps()} className="h-full w-full">
      <input {...getInputProps()} />
      <button
        type="button"
        className={classNames(
          "h-full w-full border border-dashed border-pimento-blue bg-pimento-blue bg-opacity-5",
          className,
        )}
        onClick={() => openFileBrowser()}
      >
        <div className="flex-col-center gap-3xl max-h-[250px]">
          <input {...getInputProps()} />
          {content}
        </div>
      </button>
    </div>
  );
};
