import type { ReactNode } from "react";

export const SettingsMenuLayout = ({
  body,
  footer,
}: {
  body: ReactNode;
  footer: ReactNode;
}) => (
  <div className="flex-col flex-fill">
    <div className="flex-shrink overflow-auto">{body}</div>
    <div className="mb-auto">{footer}</div>
  </div>
);
