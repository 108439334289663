import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";

export const NewStyleTile = () => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  return (
    <BaseButton
      className="rounded-xs group flex-col-center items-center gap-md bg-gray-100 hover:bg-gray-200"
      style={{ height: 220, width: 180 }}
      onClick={createStyle}
      loading={isCreateStyleLoading}
    >
      <Icon name="Plus" className="stroke-xl" size={24} />
      <div className="text-lg font-semibold">New model</div>
    </BaseButton>
  );
};
