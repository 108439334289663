import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import type {
  ContentGenerationInformation,
  ImageContent,
} from "../../../types.ts";

export const ImageDebugInformation = ({ image }: { image: ImageContent }) => {
  const { data: imageGenerationParameters } =
    useAppQuery<ContentGenerationInformation>({
      queryKey: `contents/${image.uuid}/generation-parameters`,
    });

  return (
    <div className="flex-col gap-md">
      <span className="font-semibold">Debug mode</span>
      <div className="flex-col gap-sm">
        {imageGenerationParameters?.node_generation_parameters.map(
          (it, index) => (
            <div key={index} className="flex-col">
              <div className="flex-row overflow-x-auto gap-md">
                <span className="font-semibold">Node</span> {it.name}
              </div>
              <div className="flex-row gap-md">
                <span className="font-semibold">Status</span> {it.status}
              </div>
              <div className="flex-row gap-md">
                <span className="font-semibold">Started at</span>{" "}
                {it.started_at
                  ? new Date(it.started_at).toLocaleString()
                  : null}
              </div>
              <div className="flex-row gap-md">
                <span className="font-semibold">Ended at</span>
                {it.ended_at ? new Date(it.ended_at).toLocaleString() : null}
              </div>
              <div className="flex-col">
                <span className="font-semibold">Parameters</span>
                {Object.keys(it.parameters).map((key) => (
                  <div
                    key={key}
                    className="flex-row overflow-x-auto gap-sm pl-sm text-sm"
                  >
                    <span className="font-semibold">{key} </span>
                    <span className="text-wrap">
                      {JSON.stringify(
                        it.parameters[key as keyof typeof it.parameters],
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};
