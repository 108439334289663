import { Icon } from "../../../../components/Icon/Icon.tsx";

export const ErrorMessage = ({ text }: { text: string }) => (
  <div className="flex-row items-center gap-sm">
    <Icon
      name="TriangleAlert"
      size={14}
      className="stroke-xl stroke-error-red-200"
    />
    <span className="flex-shrink text-error-red-200 text-wrap">{text}</span>
  </div>
);
