export const EmptyStateInformation = ({
  title,
  body,
}: {
  title: string;
  body: string;
}) => (
  <div className="flex-col-center flex-fill">
    <div className="font-burg text-5xl">{title}</div>
    <span className="text-lg">{body}</span>
  </div>
);
