import { ErrorMessage } from "../../../components/ErrorMessage.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { InitialImagePicker } from "./InitialImagePicker.tsx";

export const InitialImageWrappedSection = ({
  imageUuid,
  onImageUuidChange,
  error,
}: {
  imageUuid?: string;
  onImageUuidChange: (uuid: string | undefined) => void;
  error?: string;
}) => (
  <SettingsSectionWrapper
    name="Initial image"
    content={
      <div className="flex-col gap-md">
        <InitialImagePicker
          imageUuid={imageUuid}
          onImageUuidChange={onImageUuidChange}
          isError={!!error}
        />
        {error && <ErrorMessage text={error} />}
      </div>
    }
  />
);
