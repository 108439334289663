import classNames from "classnames";
import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Switch } from "../../../components/Switch/Switch.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StylePreview } from "../components/StylePreview.tsx";

export const ObjectAndCharacterDescriptionForm = ({
  style,
  onContinue,
  onGoBack,
  widthClassName,
}: {
  style: Style;
  onContinue: () => void;
  onGoBack: () => void;
  widthClassName?: string;
}) => {
  const [subjectDescription, setSubjectDescription] = useState<string>("");
  const [enableFluxTraining, setEnableFluxTraining] = useState(true);

  const trainStyle = useAppMutation({
    path: "styles/train",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => onContinue(),
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-fill overflow-y-auto">
      <ModelCreationBackButton
        onClick={onGoBack}
        className="absolute top-16 left-16"
      />
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <div
        className={classNames(
          "flex-col flex-fill pt-[80px] gap-3xl items-center",
          widthClassName,
        )}
      >
        <StylePreview style={style} title={`describe your ${style.type}`} />
        <div className="w-full flex-col gap-md">
          <div className="font-semibold">
            Be precise and use less than 5 words
          </div>
          <input
            type="text"
            className="w-full h-3xl py-md px-lg rounded-sm border placeholder:text-gray-300 placeholder:italic"
            placeholder={
              style.type === "object"
                ? "Minimalist black rectangular bag"
                : "Young blonde woman"
            }
            value={subjectDescription}
            onChange={(event) => {
              setSubjectDescription(event.target.value);
            }}
          />
          <div>
            <div className="text-gray-400 italic">Examples:</div>
            <ul className="pl-lg list-disc italic text-gray-400">
              {(style.type === "character"
                ? CHARACTER_EXAMPLES
                : OBJECT_EXAMPLES
              ).map((example) => (
                <li key={example} className="text-inherit">
                  {example}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full flex-row gap-md">
          <div className="font-semibold">Use Pimento v2</div>
          <Switch value={enableFluxTraining} onChange={setEnableFluxTraining} />
        </div>
        <Button
          className="w-full"
          disabled={subjectDescription.isEmpty()}
          onClick={() => {
            trainStyle.mutate({
              uuid: style.uuid,
              instance_prompt: `${CONCEPT_TOKEN} ${subjectDescription}, `,
              concept_token: CONCEPT_TOKEN,
              flux_training_enabled: enableFluxTraining,
            });
            onContinue();
          }}
          loading={trainStyle.isLoading}
        >
          {subjectDescription.isEmpty()
            ? `Describe your ${style.type}`
            : "Create my model"}
        </Button>
      </div>
    </div>
  );
};

const CONCEPT_TOKEN = "TOK";

const OBJECT_EXAMPLES = [
  "minimalist black rectangular bag",
  "cushion-cut canary yellow ring diamond",
  "sleek black stylish tech-enhanced sunglasses",
] as const;

const CHARACTER_EXAMPLES = [
  "young and tall blond woman",
  "cute pink panther stuffed toy",
  "55 year-old black man",
] as const;
