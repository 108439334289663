import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { IconName } from "../../../components/Icon/Icon.tsx";
import {
  BOARDS,
  GENERATE_TOOL_PATH,
  TRANSFER_TOOL_PATH,
} from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";
import { useCreateStyle } from "../hooks/useCreateStyle.ts";
import { ActionTileButton } from "./ActionTileButton.tsx";

export const ActionsSection = () => {
  // XXX: We use ref and not local state here to avoid having rendering order issue. The actionSelected state is
  // not necessary updated otherwise when used in the onSuccess that can lead to unexpected behavior.
  const actionSelected = useRef<HomepageAction | null>(null);
  const navigate = useNavigate();

  const { createBoard } = useCreateBoard({
    onBoardCreationForbidden: () => upgradeDialogStore.setDialogOpen(true),
    onSuccess: (uuid) => {
      if (actionSelected.current === null) return;
      navigate(`${BOARDS}/${uuid}/${actionSelected.current.route}`);
    },
  });

  return (
    <div className="px-3xl flex-col gap-lg">
      <div className="text-2xl font-semibold">Get started</div>
      <div className="flex-row flex-wrap items-center gap-2xl">
        <NewStyleButton />
        {CreateActions.map((action, index) => (
          <ActionTileButton
            key={index}
            title={action.title}
            description={action.description}
            iconName={action.iconName}
            onClick={() => {
              actionSelected.current = action;
              createBoard();
            }}
          />
        ))}
      </div>
    </div>
  );
};

type HomepageAction = {
  title: string;
  description?: string;
  iconName: IconName;
  route: typeof GENERATE_TOOL_PATH | typeof TRANSFER_TOOL_PATH;
};

const CreateActions: HomepageAction[] = [
  {
    title: "Generate images",
    description: "Bring ideas to life",
    iconName: "Sparks",
    route: GENERATE_TOOL_PATH,
  },
  {
    title: "Transfer a style",
    description: "Restyle any image",
    iconName: "StyleTransfer",
    route: TRANSFER_TOOL_PATH,
  },
];

const NewStyleButton = () => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();
  return (
    <ActionTileButton
      title="Build your own model"
      iconName="Palette"
      onClick={() => createStyle()}
      isLoading={isCreateStyleLoading}
    />
  );
};
