import classNames from "classnames";
import { ClickableIcon } from "../../../../../../components/Icon/ClickableIcon.tsx";
import { useAppMutation } from "../../../../../../http/useAppMutation.ts";
import { ImageTile } from "../../../../components/ImageTile.tsx";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../../../hooks/useSelectedAssetUuid.ts";
import type { BoardImageContent } from "../../../../types.ts";
import { imageStripeDisplayStore } from "../../stores/imageStripeDisplayStore.ts";

export const ImageThumbnail = ({
  image,
  onClick,
  isSelected = false,
  isDisabled,
  className,
  fit,
}: {
  image: BoardImageContent;
  onClick?: () => void;
  isSelected?: boolean;
  isDisabled: boolean;
  className?: string;
  fit: "width" | "height";
}) => {
  const { board } = useBoard();
  const { setSelectedAssetUuid } = useSelectedAssetUuid();
  const { mutate: createAsset } = useAppMutation({
    path: "assets/create",
    invalidate: [`boards/${board.uuid}`],
    onSuccess: () => {
      imageStripeDisplayStore.setIsImageStripeOpen(true);
    },
    onCacheUpdated: (response: { data: { uuid: string } }) => {
      setSelectedAssetUuid(response.data.uuid);
    },
  }).mutation;

  return (
    <div
      className={classNames(
        "flex-col-center relative group",
        fit === "width" ? "w-full" : "h-full",
      )}
    >
      <ClickableIcon
        name="CopyPlus"
        className="absolute bottom-8 z-10 tile-action invisible group-hover:visible"
        tooltip={{ side: "bottom", content: "Save copy as new asset" }}
        onClick={() =>
          createAsset({ board_uuid: board.uuid, content_uuid: image.uuid })
        }
      />
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          className,
          fit === "width" ? "w-full" : "h-full",
          isSelected ? "border-2 border-gray-700" : "",
          isDisabled ? "" : "hover:brightness-75",
        )}
        disabled={isDisabled}
      >
        <ImageTile
          image={image}
          fit={fit}
          loadingContentClassName="text-xs !p-0 !bg-gray-100"
        />
      </button>
    </div>
  );
};
