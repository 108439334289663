import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { HOME } from "../../../routes.ts";

export const ModelCreationCloseButton = ({
  className,
}: {
  className: string;
}) => (
  <Link
    to={HOME}
    className={classNames(
      "rounded-sm border border-gray-300 bg-white text-gray-600",
      className,
    )}
  >
    <Icon
      name="X"
      size={32}
      className="p-md rounded-sm bg-white text-gray-600 hover:bg-gray-200"
    />
  </Link>
);
