import { useMemo } from "react";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import type { Style } from "../types.ts";

export const useStyles = () => {
  const stylesQuery = useAppQuery<Style[]>({ queryKey: "styles" });

  const userStyles = stylesQuery.data?.filter((board) => !board.is_curated);
  const libraryStyles = stylesQuery.data?.filter((board) => board.is_curated);

  const libraryStylesCountByTags = useMemo(
    () =>
      libraryStyles
        ?.flatMapNotNull((libraryBoard) => libraryBoard.tags)
        .reduce(
          (accumulator, value) => {
            accumulator[value] =
              accumulator[value] !== undefined ? accumulator[value]! + 1 : 1;
            return accumulator;
          },
          {} as {
            [Key: string]: number | undefined;
          },
        ),
    [libraryStyles],
  );

  return {
    userStyles,
    libraryStyles,
    libraryStylesCountByTags,
  };
};
