import { z, ZodType } from "zod";

export const zTrimRequired = z.string().transform((v, ctx) => {
  if (v.isBlank()) ctx.addIssue({ code: "custom", message: "Required" });
  return v.trim();
});

export const zTrimOrNull = z
  .string()
  .nullable()
  .transform((v) => v?.trimOrNull() ?? null);

export const addNullInput = <T extends ZodType>(
  value: T,
  message = "Required",
) =>
  value.nullable().transform((v, ctx) => {
    if (v === null) ctx.addIssue({ code: "custom", message });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    return v!;
  });
