import { enableMapSet, produce } from "immer";
import type { ZodTypeAny } from "zod";
import { create as zustandCreate } from "zustand";
import { combine } from "zustand/middleware";
import { shallow as shallowFn } from "zustand/shallow";

export type Store<
  State extends Record<string, unknown>,
  Actions extends Record<string, unknown>,
> = ReturnType<typeof createStore<State, Actions>>;

enableMapSet();

export const createStore = <
  State extends Record<string, unknown>,
  Actions extends Record<string, unknown>,
>(
  initialState: State,
  create: (props: {
    set: (value: Partial<State>) => void;
    get: () => State;
    update: (fn: (state: State) => void) => void;
    setter: <K extends keyof State>(key: K) => (value: State[K]) => void;
  }) => Actions,
) => {
  const store = zustandCreate(
    combine({ state: initialState }, (_set, get) => {
      const set = (value: Partial<State>) => {
        _set({ state: { ...get().state, ...value } });
      };
      return {
        actions: create({
          set,
          get: () => get().state,
          update: (fn) => set(produce(get().state, fn)),
          setter: (key) => (value) => {
            // @ts-expect-error When evaluating as an object key, keyof S1 becomes string 😥
            set({ [key]: value });
          },
        }),
      };
    }),
  );

  return {
    ...store.getState().actions,
    useState: <Selection = State>(
      selector?: (state: State, shallow?: true) => Selection,
      shallow?: boolean,
    ) => {
      if (!shallow) {
        return store((s) =>
          selector ? selector(s.state) : s.state,
        ) as Selection;
      }
      return store(
        (s) => (selector ? selector(s.state) : s.state),
        shallowFn,
      ) as Selection;
    },
    getState: () => store.getState().state,
  };
};

export const createStorageStore = <
  Schema extends ZodTypeAny,
  Actions extends Record<string, unknown>,
>(
  storage: Storage,
  key: string,
  schema: Schema,
  initialState: Schema["_output"],
  create: Parameters<typeof createStore<Schema["_output"], Actions>>[1],
) =>
  createStore<Schema["_output"], Actions>(
    storage.getItem(key)?.let((it) => {
      const result = schema.safeParse(JSON.parse(it));
      if (!result.success) return null;
      return result.data;
    }) ?? initialState,
    ({ set: _set, get }) => {
      const set = (payload: Partial<Schema["_output"]>) => {
        _set({ ...get(), ...payload });
        const stringified = JSON.stringify(get());
        if (stringified === storage.getItem(key)) return;
        storage.setItem(key, stringified);
      };
      return create({
        set,
        get,
        update: (fn) => set(produce(get(), fn)),
        setter: (field) => (value) =>
          // @ts-expect-error When evaluating as an object key, keyof S1 becomes string 😥
          set({ [field]: value }),
      });
    },
  );
