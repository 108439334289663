import { createStore } from "../../../../../utils/createStore.ts";

export type ViewMode = "single" | "overlap";
export const imageViewModeStore = createStore(
  {
    viewMode: "overlap" as ViewMode,
  },
  ({ set }) => ({
    setViewMode: (viewMode: ViewMode) =>
      set({
        viewMode,
      }),
  }),
);
