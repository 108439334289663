import classNames from "classnames";
import { type ReactElement, useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style, StyleImagesKind } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StylePreview } from "../components/StylePreview.tsx";

export const StyleImagesKindForm = ({
  style,
  onContinue,
  onGoBack,
}: {
  style: Style;
  onContinue: () => void;
  onGoBack: () => void;
}) => {
  const [styleImagesKind, setStyleImagesKind] = useState<StyleImagesKind>();

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-shrink overflow-y-auto pt-[80px] gap-[100px]">
      <ModelCreationBackButton
        onClick={onGoBack}
        className="absolute top-16 left-16"
      />
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <StylePreview style={style} title="what best describe your style" />
      <div className="flex-col-center flex-fill gap-[100px]">
        <div className="flex-row w-full justify-center flex-wrap gap-xl">
          {Object.keys(STYLE_BUTTONS).map((it) => (
            <BaseButton
              key={it}
              onClick={() => setStyleImagesKind(it)}
              className={classNames(
                it === styleImagesKind
                  ? "border-gray-600"
                  : "hover:border-gray-350",
                "max-w-[192px] max-h-[145px] w-full p-lg rounded-sm border-xl",
              )}
            >
              <div className="flex-col gap-lg">
                {STYLE_BUTTONS[it].header}
                <div className="flex-col text-lg">
                  <span className="font-semibold ">
                    {STYLE_BUTTONS[it].title}
                  </span>
                  <span className="text-gray-400">
                    {STYLE_BUTTONS[it].content}
                  </span>
                </div>
              </div>
            </BaseButton>
          ))}
        </div>
        <Button
          style={{ width: "300px" }}
          disabled={styleImagesKind === undefined}
          onClick={() => {
            updateStyle({
              uuid: style.uuid,
              images_kind: styleImagesKind,
            });
            onContinue();
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const STYLE_BUTTONS: Record<
  StyleImagesKind,
  { header: ReactElement; title: string; content: string }
> = {
  photography: {
    header: (
      <div className="flex-row h-3xl gap-sm">
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/photography_1.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/photography_2.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/photography_3.png"
        />
      </div>
    ),
    title: "Photography",
    content: "Realistic pictures from the real world",
  },
  illustration_2d: {
    header: (
      <div className="flex-row h-3xl gap-sm">
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/illustration_1.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/illustration_2.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/illustration_3.png"
        />
      </div>
    ),
    title: "2D Illustration",
    content: "Artworks representing objects, or concepts",
  },
  graphic_3d: {
    header: (
      <div className="flex-row h-3xl gap-sm">
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/3D_1.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/3D_2.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/3D_3.png"
        />
      </div>
    ),
    title: "3D Graphic",
    content: "Computer-generated images",
  },
  icon: {
    header: (
      <div className="flex-row h-3xl gap-sm">
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/icon_1.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/icon_2.png"
        />
        <Image
          className="w-3xl h-full rounded-xs"
          src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/images_kind/icon_3.png"
        />
      </div>
    ),
    title: "Icon",
    content: "A symbol of a brand or a pictogram",
  },
  other: {
    header: (
      <Icon
        name="Ellipsis"
        className="h-3xl w-3xl px-sm rounded-xs border fill-gray-600"
      />
    ),
    title: "Other",
    content: "If nothing fits in the previous categories",
  },
};
