import { useMemo } from "react";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useDeleteAssetOnBoard } from "../../../hooks/useDeleteAssetOnBoard.ts";
import { informationMenuDisplayStore } from "../../../stores/informationMenuDisplayStore.ts";
import type { Asset } from "../../../types.ts";
import { DownloadButton } from "./DownloadButton.tsx";

export const AssetActions = ({ asset }: { asset: Asset }) => {
  const { board } = useBoard();
  const favoriteTag = useMemo(
    () => board.tags.find((it) => it.name === FAVORITE_TAG_NAME),
    [board.tags],
  );
  const addTag = useAppMutation({
    path: "tags/add-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;
  const removeTag = useAppMutation({
    path: "tags/remove-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;

  const setIsLiked = useAppMutation({
    path: "contents/set-is-liked",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;
  const addOrRemoveFavoriteTag = () => {
    if (!favoriteTag) return;
    if (asset.is_in_favorite) {
      removeTag.mutate({
        asset_uuids: [asset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    } else {
      addTag.mutate({
        asset_uuids: [asset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    }
  };

  const { isInformationMenuOpen } = informationMenuDisplayStore.useState();

  const { mutate: deleteAssetMutation, isLoading: deleteAssetIsLoading } =
    useDeleteAssetOnBoard();

  return (
    <div className="h-full flex-fill flex-row-center">
      {favoriteTag && (
        <ClickableIcon
          name="Heart"
          iconClassName={
            asset.is_in_favorite ? "fill-pimento-red stroke-pimento-red" : ""
          }
          tooltip={{
            side: "top",
            content: asset.is_in_favorite
              ? "Remove from favorites"
              : "Add to favorites",
          }}
          onClick={() => {
            addOrRemoveFavoriteTag();
          }}
        />
      )}
      <ClickableIcon
        name="ThumbsUp"
        iconClassName={
          asset.content.is_liked === true ? "fill-pimento-blue" : ""
        }
        tooltip={
          asset.content.is_liked !== null
            ? {
                side: "top",
                content: "You can't change your feedback",
              }
            : { side: "top", content: "Give positive feedback" }
        }
        className="disabled:!opacity-100"
        isDisabled={asset.content.is_liked !== null}
        onClick={() => {
          setIsLiked.mutate({
            uuid: asset.content.uuid,
            is_liked: true,
          });
        }}
      />
      <ClickableIcon
        name="ThumbsDown"
        iconClassName={
          asset.content.is_liked === false ? "fill-pimento-red" : ""
        }
        tooltip={
          asset.content.is_liked !== null
            ? {
                side: "top",
                content: "You can't change your feedback",
              }
            : { side: "top", content: "Give negative feedback" }
        }
        className="disabled:!opacity-100"
        isDisabled={asset.content.is_liked !== null}
        onClick={() => {
          setIsLiked.mutate({
            uuid: asset.content.uuid,
            is_liked: false,
          });
        }}
      />
      <ClickableIcon
        name="Trash"
        tooltip={{ side: "top", content: "Delete" }}
        disabled={deleteAssetIsLoading || asset.deleted_at !== null}
        onClick={() => {
          deleteAssetMutation({
            uuids: [asset.uuid],
          });
        }}
      />
      <DownloadButton
        imagesToDownload={[
          {
            url: asset.content.url,
            uuid: asset.content.uuid,
          },
        ]}
      />
      <ClickableIcon
        name="Info"
        tooltip={{ side: "top", content: "Information" }}
        onClick={() =>
          informationMenuDisplayStore.toggleInformationMenuDisplayState()
        }
        iconClassName={
          isInformationMenuOpen ? "stroke-pimento-blue stroke-xl" : ""
        }
      />
    </div>
  );
};
