import { useEffect, useState } from "react";
import type { Style } from "../types.ts";
import { FORM_INPUT_WIDTH_CSS } from "./constants.ts";
import { StyleColorKindForm } from "./CreationPage/StyleColorKindForm.tsx";
import { StyleImagesKindForm } from "./CreationPage/StyleImagesKindForm.tsx";
import { StyleInitSplashScreen } from "./CreationPage/StyleInitSplashScreen.tsx";
import { StyleNameForm } from "./CreationPage/StyleNameForm.tsx";
import { StyleTrainingImagesForm } from "./CreationPage/StyleTrainingImagesForm.tsx";
import { StyleDescription } from "./StyleDescription.tsx";
import type { StyleCreationStep } from "./types.ts";

export const StyleCreation = ({ style }: { style: Style }) => {
  const [currentStyleCreationStep, setCurrentStyleCreationStep] =
    useState<StyleCreationStep>("styleTrainingImagesForm");

  useEffect(() => {
    //  XXX: If we start again the onboarding we set the step to the latest step that was reached

    if (["training", "ready"].includes(style.status)) {
      if (style.name?.length === 0 || style.name === null) {
        setCurrentStyleCreationStep("styleNameForm");
      } else if (style.images_kind === null) {
        setCurrentStyleCreationStep("styleImagesKindForm");
      } else if (style.color_kind === null) {
        setCurrentStyleCreationStep("styleColorKindForm");
      } else {
        if (style.status === "training") {
          setCurrentStyleCreationStep("styleInitSplashScreen");
        } else {
          setCurrentStyleCreationStep("styleDescription");
        }
      }
    } else {
      setCurrentStyleCreationStep("styleTrainingImagesForm");
    }
  }, [style]);

  switch (currentStyleCreationStep) {
    case "styleTrainingImagesForm":
      return (
        <StyleTrainingImagesForm
          style={style}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onContinue={() => {
            setCurrentStyleCreationStep("styleNameForm");
          }}
        />
      );
    case "styleNameForm":
      return (
        <StyleNameForm
          widthClassName={FORM_INPUT_WIDTH_CSS}
          style={style}
          onContinue={() => setCurrentStyleCreationStep("styleImagesKindForm")}
        />
      );
    case "styleImagesKindForm":
      return (
        <StyleImagesKindForm
          style={style}
          onContinue={() => {
            setCurrentStyleCreationStep("styleColorKindForm");
          }}
          onGoBack={() => {
            setCurrentStyleCreationStep("styleNameForm");
          }}
        />
      );
    case "styleColorKindForm":
      return (
        <StyleColorKindForm
          style={style}
          onContinue={() => {
            setCurrentStyleCreationStep("styleInitSplashScreen");
          }}
          onGoBack={() => {
            setCurrentStyleCreationStep("styleImagesKindForm");
          }}
        />
      );
    case "styleInitSplashScreen":
      return <StyleInitSplashScreen widthClassName={FORM_INPUT_WIDTH_CSS} />;
    case "styleDescription":
      return <StyleDescription style={style} />;
  }
};
