import { createStore } from "../../../../../utils/createStore.ts";

export const imageStripeDisplayStore = createStore(
  {
    isImageStripeOpen: false as boolean,
  },
  ({ get, set }) => ({
    toggleImageStripe: () =>
      set({
        isImageStripeOpen: !get().isImageStripeOpen,
      }),
    setIsImageStripeOpen: (open: boolean) =>
      set({
        isImageStripeOpen: open,
      }),
  }),
);
