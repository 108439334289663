import { useNavigate } from "react-router-dom";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { STYLES } from "../../../routes.ts";

export const useCreateStyle = () => {
  const { mutate, isLoading } = useAppMutation({
    path: "styles",
    invalidate: ["styles"],
    waitForInvalidation: false,
  }).mutation;

  const navigate = useNavigate();

  return {
    createStyle: () => {
      mutate(
        { name: "" },
        {
          onSuccess: ({ data }) => {
            navigate(`${STYLES}/${data.uuid as string}`);
          },
        },
      );
    },
    isCreateStyleLoading: isLoading,
  };
};
