import { useEffect, useState } from "react";
import { useAppMutation } from "../../http/useAppMutation.ts";
import type { Style } from "../types.ts";
import { FORM_INPUT_WIDTH_CSS } from "./constants.ts";
import { ObjectAndCharacterDescriptionForm } from "./CreationPage/ObjectAndCharacterDescriptionForm.tsx";
import { StyleInitSplashScreen } from "./CreationPage/StyleInitSplashScreen.tsx";
import { StyleNameForm } from "./CreationPage/StyleNameForm.tsx";
import { TrainingImagesForm } from "./CreationPage/TrainingImagesForm.tsx";
import { StyleDescription } from "./StyleDescription.tsx";
import type { ObjectAndCharacterCreationStep } from "./types.ts";

export const ObjectAndCharacterModelCreation = ({
  style,
}: {
  style: Style;
}) => {
  const [currentModelCreationStep, setCurrentModelCreationStep] =
    useState<ObjectAndCharacterCreationStep>("modelTrainingImagesForm");

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  useEffect(() => {
    //  XXX: If we start again the onboarding we set the step to the latest step that was reached
    if (["training", "ready"].includes(style.status)) {
      if (style.name?.length === 0 || style.name === null) {
        setCurrentModelCreationStep("modelNameForm");
      } else if (style.status === "training") {
        setCurrentModelCreationStep("modelInitSplashScreen");
      } else {
        setCurrentModelCreationStep("modelDescription");
      }
    } else {
      setCurrentModelCreationStep("modelTrainingImagesForm");
    }
  }, [style]);

  // FIXME: align step names and component names
  switch (currentModelCreationStep) {
    case "modelTrainingImagesForm":
      return (
        <TrainingImagesForm
          style={style}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onContinue={() => {
            setCurrentModelCreationStep("modelSubjectDescriptionForm");
          }}
          onGoBack={() => {
            // FIXME: better structure the form to avoid doing a mutation here
            updateStyle({
              uuid: style.uuid,
              type: null,
            });
          }}
          buttonName="Next"
        />
      );
    case "modelSubjectDescriptionForm":
      return (
        <ObjectAndCharacterDescriptionForm
          style={style}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onContinue={() => {
            setCurrentModelCreationStep("modelNameForm");
          }}
          onGoBack={() => {
            setCurrentModelCreationStep("modelTrainingImagesForm");
          }}
        />
      );
    case "modelNameForm":
      return (
        <StyleNameForm
          widthClassName={FORM_INPUT_WIDTH_CSS}
          style={style}
          onContinue={() =>
            setCurrentModelCreationStep("modelInitSplashScreen")
          }
        />
      );
    case "modelInitSplashScreen":
      return <StyleInitSplashScreen widthClassName={FORM_INPUT_WIDTH_CSS} />;
    case "modelDescription":
      return <StyleDescription style={style} />;
  }
};
