import { fabric } from "fabric";
import { createStore } from "../../../../../../../utils/createStore.ts";

export const generativeFillCanvasStore = createStore(
  {
    fabricCanvas: undefined as fabric.Canvas | undefined,
    numObjectsCreated: 0 as number,
  },
  ({ get, setter }) => ({
    setFabricCanvas: setter("fabricCanvas"),
    addObject: () => {
      setter("numObjectsCreated")(get().numObjectsCreated + 1);
    },
    removeObject: () => {
      setter("numObjectsCreated")(get().numObjectsCreated - 1);
    },
  }),
);
