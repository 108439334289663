import classNames from "classnames";
import { useMemo, useState } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { ClickableIcon } from "../../../../components/Icon/ClickableIcon.tsx";
import { useClickOutside } from "../../../../hooks/useOnClickOutside.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { tagsFilterStore } from "../../stores/tagsFilterStore.ts";
import type { Tag } from "../../types.ts";

export const TagsFilterBar = ({ tagsFilter }: { tagsFilter: Tag[] }) => {
  const { selectedTagsFilter } = tagsFilterStore.useState();
  const tagsAndSelectionInformation = useMemo(
    () =>
      tagsFilter
        // Set Favorite at the first position for display purpose
        .filter((tag) => tag.name === FAVORITE_TAG_NAME)
        .concat(tagsFilter.filter((tag) => tag.name !== FAVORITE_TAG_NAME))
        .map((tag) => ({
          isSelected: selectedTagsFilter.some((it) => it.uuid === tag.uuid),
          tag,
        })),
    [tagsFilter, selectedTagsFilter],
  );

  return (
    <div className="flex-row flex-fill px-xl gap-md items-center">
      {/* FIXME: Define and use secondary button instead*/}
      <BaseButton
        className={classNames(
          "rounded-sm text-sm text-center items-center justify-center px-sm py-xs",
          selectedTagsFilter.length === 0
            ? "bg-pimento-blue text-white"
            : "bg-transparent",
        )}
        onClick={() => {
          tagsFilterStore.resetTagsFilter();
        }}
        name="All"
      >
        All
      </BaseButton>
      {tagsAndSelectionInformation.map((tag) => (
        <TagFilterButton
          key={tag.tag.uuid}
          tag={tag.tag}
          onClick={() => {
            if (tag.isSelected) {
              tagsFilterStore.removeTagFromFilter({ uuid: tag.tag.uuid });
            } else {
              tagsFilterStore.resetTagsFilter();
              tagsFilterStore.addTagToFilter(tag.tag);
            }
          }}
          onDeletionSuccess={() => {
            if (tag.isSelected) tagsFilterStore.resetTagsFilter();
          }}
          isSelected={tag.isSelected}
        />
      ))}
      {/* FIXME: Once design/UX ready for this "Create new tag", we should add onClick action and uncomment following actions */}
      {/* <Button*/}
      {/*  overrideClassName*/}
      {/*  className="italic text-gray-500 bg-transparent rounded-[5px] text-sm text-center items-center justify-center px-[3px] pt-[2px] pb-[1px]"*/}
      {/*  onClick={undefined}*/}
      {/*  name="+ Create new tag"*/}
      {/*  label="+ Create new tag"*/}
      {/*/ >*/}
    </div>
  );
};

const TagFilterButton = ({
  tag,
  isSelected,
  onClick,
  onDeletionSuccess,
}: {
  tag: Tag;
  isSelected: boolean;
  onClick: () => void;
  onDeletionSuccess: () => void;
}) => {
  const { board } = useBoard();
  const deleteTag = useAppMutation({
    path: "tags/delete",
    invalidate: [`boards/${board.uuid}`],
    onSuccess: () => {
      onDeletionSuccess();
    },
  }).mutation;
  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] =
    useState<boolean>(false);
  const ref = useClickOutside(() => setIsDeletionConfirmationOpen(false));

  const commonClassName =
    "rounded-sm text-sm text-center items-center justify-center px-sm py-xs";

  if (tag.name === FAVORITE_TAG_NAME) {
    return (
      <ClickableIcon
        name="Heart"
        size={15}
        iconClassName={classNames(
          "stroke-[1.6px] mb-2",
          isSelected
            ? "stroke-pimento-red fill-pimento-red"
            : "hover:stroke-pimento-red hover:fill-pimento-red",
        )}
        className={classNames("ml-4 !px-sm", commonClassName)}
        tooltip={{
          side: "bottom",
          content: "Favorites",
        }}
        onClick={onClick}
      />
    );
  }
  return (
    <div ref={ref} className="flex-row relative group">
      {isDeletionConfirmationOpen ? (
        <BaseButton
          className="px-sm py-xs rounded-sm text-white text-sm bg-pimento-red hover:opacity-80"
          disabled={deleteTag.isLoading}
          onClick={() => {
            deleteTag.mutate({ uuid: tag.uuid });
          }}
        >
          Confirm tag deletion
        </BaseButton>
      ) : (
        <>
          <BaseButton
            className={classNames(
              commonClassName,
              isSelected
                ? "bg-pimento-blue text-white"
                : "bg-transparent group-hover:bg-pimento-blue group-hover:text-white",
            )}
            onClick={onClick}
          >
            {tag.name}
          </BaseButton>
          <ClickableIcon
            name="X"
            iconClassName="rounded-full bg-white"
            className={classNames(
              "!p-0 invisible absolute -top-6 -right-6 ",
              isSelected ? "group-hover:visible" : "",
            )}
            tooltip={{
              side: "top",
              content: "Delete tag",
            }}
            onClick={() => {
              setIsDeletionConfirmationOpen(true);
            }}
            size={14}
          />
        </>
      )}
    </div>
  );
};
