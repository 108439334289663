import * as DialogPrimitive from "@radix-ui/react-dialog";
import classNames from "classnames";
import type { ReactNode } from "react";
import { Icon } from "../Icon/Icon.tsx";

export const Dialog = ({
  content,
  isOpen,
  onOpenChange,
  className,
  overlayClassName,
  displayCloseButton = true,
  clickOutsideAuthorized = false,
  escapeKeyDownAuthorized = true,
  iconSize,
  ...props
}: {
  content: ReactNode;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  className?: string;
  overlayClassName?: string;
  displayCloseButton?: boolean;
  clickOutsideAuthorized?: boolean;
  escapeKeyDownAuthorized?: boolean;
  iconSize?: number;
}) => (
  <DialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay
        className={classNames(
          "fixed inset-0 z-50 bg-gray-600/60 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
          overlayClassName,
        )}
      />
      <DialogPrimitive.Content
        className={classNames(
          className,
          "fixed left-[50%] top-[50%] z-50 grid translate-x-[-50%] translate-y-[-50%] gap-sm border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
        )}
        onPointerDownOutside={
          clickOutsideAuthorized ? undefined : (e) => e.preventDefault()
        }
        onOpenAutoFocus={(e) => e.preventDefault()}
        onEscapeKeyDown={
          escapeKeyDownAuthorized ? undefined : (e) => e.preventDefault()
        }
        {...props}
      >
        {content}
        {displayCloseButton && (
          <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-primary">
            <div className="flex-row-center p-md">
              <Icon name="X" size={iconSize} />
            </div>
          </DialogPrimitive.Close>
        )}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  </DialogPrimitive.Root>
);
