import { useEffect } from "react";
import { z } from "zod";
import { createStorageStore } from "../../../../../../../utils/createStore.ts";
import {
  GENERATION_RATIOS,
  QUALITY_PRESETS,
} from "../../../../../constants.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";

const zGenerateSettings = z.object({
  num_generations: z.number(),
  prompt_strength: z.number(),
  lora_scale: z.number(),
  negative_prompt: z.string(),
  prompt_enhancement: z.boolean(),
  generation_ratio: z.enum(GENERATION_RATIOS),
  quality_preset: z.enum(QUALITY_PRESETS),
});

export type GenerateSettings = z.infer<typeof zGenerateSettings>;

export const GENERATE_DEFAULT_SETTINGS = {
  num_generations: 4,
  prompt_strength: 0.8,
  lora_scale: 0.78,
  negative_prompt: "",
  prompt_enhancement: true,
  generation_ratio: "r_1_1",
  quality_preset: "fast",
} as const;

export const useGenerateSettingsAtom = createStorageStore(
  localStorage,
  "board-generate-settings-v4",
  z.record(zGenerateSettings.optional()),
  {},
  ({ update }) => ({
    updateGenerateSettings: (
      boardUuid: string,
      newSettings: Partial<GenerateSettings>,
    ) => {
      update((draft) => {
        const existingSettings = draft[boardUuid]!;
        draft[boardUuid] = { ...existingSettings, ...newSettings };
      });
    },
    resetGenerateSettings: (boardUuid: string) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = {
          ...existingSettings,
          ...GENERATE_DEFAULT_SETTINGS,
        };
      });
    },
  }),
);

export const useDefaultGenerateSettingsAtom = createStorageStore(
  localStorage,
  "board-default-generate-settings-v3",
  z.record(zGenerateSettings.optional()),
  {},
  ({ update }) => ({
    updateDefaultGenerateSettings: (
      boardUuid: string,
      newSettings: GenerateSettings,
    ) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = { ...existingSettings, ...newSettings };
      });
    },
    resetDefaultGenerateSettings: (boardUuid: string) => {
      update((draft) => {
        const existingSettings = draft[boardUuid];
        draft[boardUuid] = {
          ...existingSettings,
          ...GENERATE_DEFAULT_SETTINGS,
        };
      });
    },
  }),
);

export const useGenerateSettings = () => {
  const { board } = useBoard();
  const generateSettings = useGenerateSettingsAtom.useState(
    (s) => s[board.uuid],
  );
  const defaultGenerateSettings = useDefaultGenerateSettingsAtom.useState(
    (s) => s[board.uuid],
  );

  useEffect(() => {
    if (generateSettings === undefined) {
      useGenerateSettingsAtom.updateGenerateSettings(
        board.uuid,
        GENERATE_DEFAULT_SETTINGS,
      );
    }
    if (defaultGenerateSettings === undefined) {
      useDefaultGenerateSettingsAtom.updateDefaultGenerateSettings(
        board.uuid,
        GENERATE_DEFAULT_SETTINGS,
      );
    }
  }, [defaultGenerateSettings, generateSettings, board.uuid]);

  return {
    defaultGenerateSettings:
      defaultGenerateSettings ?? GENERATE_DEFAULT_SETTINGS,
    generateSettings: generateSettings ?? GENERATE_DEFAULT_SETTINGS,
  };
};
