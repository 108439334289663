import { PresetButton } from "../../../../components/PresetButton.tsx";
import { VARIATION_CREATIVITY_STRENGTH_PRESETS } from "./constants.ts";
import { useVariationSettings } from "./hooks/useVariationSettings.ts";

export const VariationCreativityStrengthPresetSection = () => {
  const { variationSettings, setVariationSettings } = useVariationSettings();

  return (
    <div className="flex-row-center gap-sm">
      {VARIATION_CREATIVITY_STRENGTH_PRESETS.map((preset) => (
        <PresetButton
          key={preset}
          isSelected={variationSettings.creativity_strength_preset === preset}
          onClick={() =>
            setVariationSettings({ creativity_strength_preset: preset })
          }
        >
          {preset}
        </PresetButton>
      ))}
    </div>
  );
};
