import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { BOARDS, CREATE_WORKSPACE } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";

export const UserInformationDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [occupation, setOccupation] = useState<string>();
  const [industry, setIndustry] = useState<string>();

  const userInformationMutation = useAppMutation({
    path: "users/add-information",
    invalidate: ["users/me"],
  }).mutation;

  const [step, setStep] = useState<"personalInformation" | "industry" | "job">(
    "personalInformation",
  );

  const { createBoard, isCreateBoardLoading } = useCreateBoard({
    onBoardCreationForbidden: () => upgradeDialogStore.setDialogOpen(true),
    onSuccess: (uuid) => navigate(`${BOARDS}/${uuid}/${CREATE_WORKSPACE}`),
  });

  useEffect(() => {
    if (
      user &&
      (user.first_name === null ||
        user.last_name === null ||
        user.phone_number === null ||
        user.terms_and_conditions_agreement === null)
    ) {
      setStep("personalInformation");
    } else if (user && user.industry === null) {
      setStep("industry");
    } else if (user && user.occupation === null) {
      setStep("job");
    }
  }, [user]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      displayCloseButton={false}
      className="border-0 bg-white max-w-[700px] w-full"
      content={
        step === "industry" ? (
          <UserInformationForm
            key={step}
            title="what best describes your industry?"
            options={INDUSTRY_OPTIONS}
            value={industry}
            onValueChange={setIndustry}
            onClick={() => {
              setStep("job");
            }}
          />
        ) : step === "job" ? (
          <UserInformationForm
            key={step}
            title="what best describes your job?"
            options={
              JOB_OPTIONS[
                industry && INDUSTRY_OPTIONS.includes(industry)
                  ? industry
                  : "Other"
              ]
            }
            value={occupation}
            onValueChange={setOccupation}
            isLoading={isCreateBoardLoading}
            onClick={() => {
              userInformationMutation.mutate({
                occupation,
                industry,
              });
              createBoard({
                name: "Welcome project",
                styleUuid: GENERIC_STYLE_UUID,
              });
            }}
          />
        ) : (
          <PersonalInformationForm
            onClick={({
              firstName,
              lastName,
              phoneNumber,
            }: {
              firstName: string;
              lastName: string;
              phoneNumber: string;
            }) => {
              userInformationMutation.mutate({
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber === "" ? null : phoneNumber,
                terms_and_conditions_agreement: true,
              });
              setStep("industry");
            }}
          />
        )
      }
    />
  );
};

const PersonalInformationForm = ({
  onClick,
}: {
  onClick: ({
    firstName,
    lastName,
    phoneNumber,
  }: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => void;
}) => {
  // FIXME: This should be done in the backend during `login` mutation by direct auth0 http call:
  //  https://auth0.com/docs/api/authentication#get-user-info
  const { user: auth0User } = useAuth0();

  const [firstName, setFirstName] = useState<string>(
    auth0User?.given_name ?? "",
  );
  const [lastName, setLastName] = useState<string>(
    auth0User?.family_name ?? "",
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const isFirstNameEditable = auth0User?.given_name === undefined;
  const isLastNameEditable = auth0User?.family_name === undefined;

  return (
    <div className="flex-col-center w-full px-[80px] py-[48px] gap-3xl">
      <span className="text-4xl font-burg">let's get started</span>
      <div className="flex-col gap-xl w-[270px]">
        <div className="flex-col gap-sm">
          <span className="font-semibold text-lg">First name*</span>
          <input
            disabled={!isFirstNameEditable}
            type="text"
            placeholder={isFirstNameEditable ? "Michael" : ""}
            className="w-full p-md border rounded placeholder:text-gray-350 hover:enabled:border-gray-400 focus:border-gray-400"
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
        </div>
        <div className="flex-col gap-sm">
          <span className="font-semibold text-lg">Last name*</span>
          <input
            disabled={!isLastNameEditable}
            type="text"
            placeholder={isLastNameEditable ? "Scott" : ""}
            className="w-full p-md border rounded placeholder:text-gray-350 hover:enabled:border-gray-400 focus:border-gray-400"
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
        </div>
        <div className="flex-col gap-sm">
          <span className="font-semibold text-lg">Phone number (optional)</span>
          <input
            type="number"
            placeholder="0033692304950"
            className="w-full p-md border rounded placeholder:text-gray-350 hover:enabled:border-gray-400 focus:border-gray-400 hide-controls"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
          />
        </div>
        <div className="flex-col gap-md">
          <Button
            disabled={
              (isFirstNameEditable && firstName === "") ||
              (isLastNameEditable && lastName === "")
            }
            onClick={() => onClick({ firstName, lastName, phoneNumber })}
          >
            Continue
          </Button>
          <span className="text-gray-350 text-center">
            By clicking on continue, you agree to our &nbsp;
            <a
              href="https://gopimento.notion.site/Pimento-Terms-and-Conditions-2d2e2f4f6bf84854be7abfa506ffd71d"
              target="_blank"
            >
              <span className="underline text-inherit">
                Terms & Conditions and Privacy Policy
              </span>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

const UserInformationForm = ({
  title,
  options,
  value,
  onValueChange,
  onClick,
  isLoading,
}: {
  title: string;
  options: string[];
  value: string | undefined;
  onValueChange: (value: string | undefined) => void;
  onClick: () => void;
  isLoading?: boolean;
}) => {
  const [other, setOther] = useState<string>("");

  return (
    <div className="flex-col-center w-full px-[80px] py-[48px] gap-[48px]">
      <span className="text-4xl font-burg">{title}</span>
      <div className="grid grid-cols-2 gap-xl w-full">
        {options.map((label, id) => (
          <button
            key={id}
            type="button"
            className={classNames(
              "w-full h-[40px] flex-row-center rounded-sm bg-gray-100 hover:bg-gray-200",
              {
                "border border-gray-600": value === label,
              },
            )}
            onClick={() => onValueChange(label)}
          >
            {label}
          </button>
        ))}
        <input
          type="text"
          className={classNames(
            "w-full h-[40px] flex-row-center rounded-sm bg-gray-100 hover:bg-gray-200 text-center placeholder:text-primary focus:placeholder-transparent",
            {
              "border border-gray-600": other !== "" && value === other,
            },
          )}
          placeholder="Other"
          value={other}
          onChange={(event) => {
            setOther(event.target.value);
            onValueChange(
              event.target.value === "" ? undefined : event.target.value,
            );
          }}
          onFocus={() => {
            onValueChange(other === "" ? undefined : other);
          }}
        />
      </div>
      <BaseButton
        className="w-1/2 h-[44px] rounded-sm disabled:bg-gray-300 disabled:text-gray-400 hover:bg-gray-500 bg-gray-600 text-white bg-gray flex-row-center font-semibold"
        disabled={value === undefined}
        onClick={onClick}
        loading={isLoading}
      >
        Continue
      </BaseButton>
    </div>
  );
};

const INDUSTRY_OPTIONS = [
  "Animation",
  "Branding, Marketing & Advertising",
  "Digital Creation & Art",
  "Fashion & Retail",
  "Gaming",
  "Interior Design & Architecture",
  "University or School",
  "Web & Product Design",
];

const JOB_OPTIONS: Record<(typeof INDUSTRY_OPTIONS)[number], string[]> = {
  Animation: [
    "3D Modeler",
    "Animator",
    "Character Designer",
    "Storyboard Artist",
    "Visual Effects Artist",
  ],
  "Branding, Marketing & Advertising": [
    "Brand Strategist / Manager",
    "Content Creator",
    "Copywriter",
    "Creative or Artistic Director",
    "Graphic Designer",
    "Illustrator",
    "Marketing Manager",
    "Product Manager",
    "Project Manager",
  ],
  "Digital Creation & Art": [
    "3D Artist",
    "AI Artist",
    "Content Creator",
    "Creative or Artistic Director",
    "Digital Designer",
    "Graphic Designer",
  ],
  "Fashion & Retail": [
    "Brand Manager",
    "Content Creator",
    "Creative or Artistic Director",
    "Fashion Designer",
    "Fashion Illustrator",
    "Stylist",
    "Textile Designer",
  ],
  Gaming: [
    "3D Designer",
    "Character Artist",
    "Creative or Artistic Director",
    "Game Designer, Artist",
    "Graphic Designer",
    "Storyboard Artist",
  ],
  "Interior Design & Architecture": [
    "Architect",
    "Creative Director",
    "Interior designer",
  ],
  "University or School": ["Professor", "Student"],
  "Web & Product Design": [
    "Graphic Designer",
    "Interaction Designer",
    "Product Designer",
    "UI Designer",
    "UX Designer",
    "Visual Designer",
  ],
  Other: [
    "AI Artist",
    "Content Creator",
    "Digital Creation & Art",
    "Digital Designer",
    "Graphic Designer",
    "3D Artist",
    "Architect",
  ],
};
