import { createStore } from "../../../utils/createStore.ts";

export const showDeletedAssetsStore = createStore(
  {
    showDeletedAssets: false,
  },
  ({ get, set }) => ({
    toggleShowDeletedAssets: () =>
      set({
        showDeletedAssets: !get().showDeletedAssets,
      }),
  }),
);
