import classNames from "classnames";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useCreateStyle } from "../../Homepage/hooks/useCreateStyle.ts";
import type { Style } from "../../Homepage/types.ts";

export const StyleSelectionSection = ({
  styles,
  selectedStyleUuid,
  onStyleUuidSelect,
}: {
  styles: Style[];
  selectedStyleUuid: string | undefined;
  onStyleUuidSelect: (styleUuid: string) => void;
}) => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  return (
    <div className="flex-col w-[204px] border-r">
      <BaseButton
        loading={isCreateStyleLoading}
        onClick={createStyle}
        className="group flex-row items-center h-[48px] px-lg gap-sm hover:bg-gray-300"
      >
        <Icon name="Plus" size={20} className="mb-2" />
        <span className="text-lg font-semibold">Add new model</span>
      </BaseButton>
      <div className="flex-col flex-fill overflow-y-auto">
        {styles.map((style) => (
          <BaseButton key={style.uuid}>
            <div
              className={classNames(
                selectedStyleUuid === style.uuid
                  ? "bg-gray-300"
                  : "hover:bg-gray-200",
                "flex-row h-3xl px-lg gap-md items-center",
              )}
              onClick={() => onStyleUuidSelect(style.uuid)}
            >
              {style.thumbnail_url ? (
                <Image
                  src={style.thumbnail_url}
                  className="w-2xl h-2xl aspect-square"
                  imageClassName="h-full w-full object-cover object-center"
                />
              ) : (
                <div className="w-[32px] h-[32px] flex-row-center bg-pimento-blue">
                  <Icon
                    size={16}
                    name="PimentoStar"
                    className=" stroke-transparent fill-white"
                  />
                </div>
              )}
              <span
                className={classNames(
                  style.name?.isBlank() && "italic",
                  "truncate w-[70%]",
                )}
              >
                {style.name ? style.name : "Untitled"}
              </span>
            </div>
          </BaseButton>
        ))}
      </div>
    </div>
  );
};
